import React from 'react'
import { sankey, sankeyLinkHorizontal } from 'd3-sankey'
import { cloneDeep, flatMap, groupBy, toPairs, uniq } from 'lodash'

import { ApiWasteFootprint } from '../../api/src/common-types'
import { wasteDisposalColorMap } from './PlanetWasteGraphs'
import { NoDataView } from './BaseGraphs/NoDataView'
import { LoadingSkeleton } from './LoadingSkeleton'

import './PlanetWasteMap.scss'
import { ChartContainer, GeneratorProps } from './BaseGraphs/ChartContainer'
import { formatAbsoluteNumber } from './Utils/format'
import { SumIndicator } from './BaseGraphs/Indicators'

import colours from '../Colours.module.scss'

export const wasteGroupColorMap = {
  'Cardboard / Paper': colours.lightBlue3,
  Glass: colours.lightPink2,
  Metals: colours.green1,
  'Food waste': colours.orange,
  'Organic waste (non-food)': colours.blue5,
  Furniture: colours.yellow3,
  Plastics: colours.lightBlue4,
  Textiles: colours.lightGreen,
  Wood: colours.offWhite7,
  'Mixed non-hazardous waste': colours.lightBlue5,
  'Electrical / Electronical': colours.lightPink3,
  'Mixed hazardous waste': colours.green2,
  'Mixed Furniture': colours.orange1,
  'Glass and ceramics': colours.lightPink2
}

interface PlanetWasteMapProps {
  data: ApiWasteFootprint[] | undefined
}

interface Link {
  id: string
  color: string
  source: string
  target: string
  value: number
}

export function PlanetWasteMapBase({ data }: PlanetWasteMapProps) {
  const links = React.useMemo(() => {
    return calculateLinks(data)
  }, [data])

  return data === undefined ? (
    <LoadingSkeleton />
  ) : data.length === 0 ? (
    <NoDataView />
  ) : (
    <ChartContainer
      series={[{ name: 'dummy', color: 'transparent', data: [] }]}
      generator={sankeyGenerator(links)}
      domain={[]}
      dateFormat="fy"
      hideGuides
      hideXAxis
      hideYAxis
      hideLegend
      disableIndicator
      isWasteChart
      tooltipSvgTargetFn={(name, type) => {
        const data = links.filter(l => name != null && l.id.includes(name))
        if (data.length === 0) {
          return undefined
        }
        return {
          heading: type === 'target' ? data[0].target : data[0].source,
          subtitle: type === 'link' ? `to ${data[0].target}` : undefined,
          body: [
            data.map(d => ({
              title: type === 'source' ? d.target : d.source,
              value: formatAbsoluteNumber(Math.round(d.value)),
              color: 'transparent',
              hideBullet: true
            }))
          ],
          summary: [
            {
              icon: <SumIndicator />,
              title: 'Total',
              unit: 'kg',
              value: formatAbsoluteNumber(Math.round(data.reduce((acc, d) => acc + d.value, 0)))
            }
          ],
          hideIndicator: true
        }
      }}
    />
  )
}

interface WasteSankeyData {
  wasteType: string
  disposalTypeDescription: string
  raw: number
}

export const calculateLinks = (data: WasteSankeyData[] | undefined) => {
  const groupedWaste = groupBy(data, d => `${d.wasteType} -> ${d.disposalTypeDescription}`)
  return toPairs(groupedWaste)
    .map(waste => {
      const [wasteName, disposal] = waste[0].split(' -> ')
      const sum = waste[1].reduce((acc, w) => acc + w.raw, 0)
      return {
        id: `${wasteName}-${disposal}`,
        color: wasteGroupColorMap[wasteName as keyof typeof wasteGroupColorMap],
        source: wasteName,
        target: disposal,
        value: sum
      }
    })
    .filter(w => w.value > 0)
}

export const sankeyGenerator = (links: Link[]) => {
  return function ({ xScale, yScale }: GeneratorProps) {
    if (links.length === 0) return []

    const leftMargin = 148
    const rightMargin = 180
    const graphHeight = yScale.range()[0]

    const sankeyData = sankey<{ id: string }, Link>()
      .extent([
        [leftMargin, 0],
        [xScale.range()[1] - rightMargin, graphHeight]
      ])
      .nodeWidth(12)
      .nodePadding(12)
      .nodeId(n => n.id)({
      nodes: uniq(flatMap(links, l => [l.source, l.target])).map(id => ({ id })),
      links: cloneDeep(links) // sankey modifies parameter data into different format, thus using a copy
    })

    const linkGenerator = sankeyLinkHorizontal<{ id: string }, { id: string }>()
    const linkElements = sankeyData.links.map((link, i) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const endColor = wasteDisposalColorMap[(link.target as any).id]
      return (
        <g key={i}>
          <defs>
            <linearGradient
              id={`gradient${i}`}
              x1={0}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              x2={(link.target as any).x0}
              y1={link.y0}
              y2={link.y1}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={link.color} offset="0%" />
              <stop stopColor={endColor} offset="100%" />
            </linearGradient>
          </defs>
          <path
            className="SankeyElement"
            d={linkGenerator(link) ?? undefined}
            strokeWidth={Math.max(link.width ?? 0, 1)}
            stroke={`url(#gradient${i})`}
            strokeOpacity={0.5}
            fill="none"
            data-name={link.id}
            data-type="link"
          />
        </g>
      )
    })
    const nodeElements = flatMap(sankeyData.nodes, (node, i) => {
      if (node.x0 == null || node.x1 == null || node.y0 == null || node.y1 == null) {
        return []
      }
      const color = wasteDisposalColorMap[node.id] ?? wasteGroupColorMap[node.id as keyof typeof wasteGroupColorMap]
      const isLeft = node.x0 === leftMargin
      const height = node.y1 - node.y0
      return (
        <g key={`node${i}`}>
          <rect
            className="SankeyElement"
            x={node.x0}
            y={node.y0}
            width={node.x1 - node.x0}
            height={height > 0 ? height : 0}
            fill={color}
            data-name={node.id}
            data-type={isLeft ? 'source' : 'target'}
          />
          <text
            x={isLeft ? node.x0 - 4 : node.x1 + 4}
            y={node.y0 + (node.y1 - node.y0) / 2}
            textAnchor={isLeft ? 'end' : 'start'}
            fontFamily='"Noto Sans", sans-serif'
            fontSize="11px"
            alignmentBaseline="middle"
            fill={color}
            style={{ textTransform: 'none' }}
          >
            {node.id}
          </text>
        </g>
      )
    })

    return [...linkElements, ...nodeElements]
  }
}
