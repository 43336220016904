import React from 'react'

const Resourse = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.84387 8.91565C7.63845 8.27865 7.52749 7.59922 7.52749 6.89387C7.52749 3.25908 10.4741 0.3125 14.1089 0.3125C15.5646 0.3125 16.9101 0.785158 17.9999 1.58538L14.1496 5.44146L15.5651 6.85702L19.4146 2.9991C20.2165 4.08967 20.6902 5.43644 20.6902 6.89387C20.6902 10.5287 17.7437 13.4752 14.1089 13.4752C13.4032 13.4752 12.7235 13.3642 12.0862 13.1586L4.24264 21.0022L0 16.7595L7.84387 8.91565ZM18.6902 6.89387C18.6902 9.4241 16.6391 11.4752 14.1089 11.4752C13.2049 11.4752 12.3621 11.2135 11.6522 10.7616L4.24264 18.1737L2.82843 16.7595L10.2387 9.34662C9.78832 8.63748 9.5275 7.79614 9.5275 6.89387C9.5275 4.36365 11.5786 2.3125 14.1089 2.3125C14.2197 2.3125 14.3295 2.3164 14.4382 2.32408L12.7354 4.02724C11.9543 4.80829 11.9543 6.07462 12.7354 6.85567L14.1509 8.27124C14.932 9.05228 16.1983 9.05228 16.9793 8.27123L18.6791 6.57143C18.6865 6.67789 18.6902 6.78541 18.6902 6.89387Z"
        fill="#111111"
      />
    </svg>
  )
}

export default Resourse
