import React from 'react'
import classNames from 'classnames'
import { isAfter } from 'date-fns'
import { isEqual } from 'lodash'

import { useFeedbackContext, useNotificationsContext } from '../context'
import { FeedbackModal } from './FeedbackModal'
import { centreSiteFunctions, LocationSearch, retailSiteFunctions } from './LocationSearch'
import { MultiSelectionMenu } from './FilterMenu/MultiSelectionMenu'
import { NotificationsModal } from './NotificationsModal'
import { functionList, useSharedSelections } from '../SharedSelections'
import { Tooltip } from './Tooltip'
import { Notifications } from './Utils/proto_cms'

import { ReactComponent as FeedbackIcon } from '../images/Icons/FeedbackIcon.svg'
import { ReactComponent as NotificationIcon } from '../images/Icons/Notification.svg'

import './TopBar.scss'
import { SiteFunction } from '../../api/src/common-types'
import { getLocationId } from './Utils/utils'
import { Navigation } from './Navigation'
import { useLocations } from '../context'
import { Route } from '../routes'
import { analyticsEvent } from './Utils/analytics'

interface TopBarProps {
  currentPage: string
  useInFlexLayout?: boolean
  exploreButton?: JSX.Element
}

export const TopBar: React.FC<TopBarProps> = props => {
  const { currentPage, useInFlexLayout, exploreButton } = props
  const [{ functionArea }, updateSharedSelections] = useSharedSelections()
  const { isFeedbackOpen, openFeedbackModal, closeFeedbackModal } = useFeedbackContext()
  const [areNotificationsOpen, setNotificationsOpen] = React.useState(false)

  const onLocationSelected = (locationId: string, functionCode?: SiteFunction) => {
    const updates = { pathname: `/${locationId}/${currentPage}` }

    if (functionCode) {
      return updateSharedSelections({ ...updates, func: [functionCode] })
    }

    updateSharedSelections(updates)
  }

  const { notificationsSeen } = useNotificationsContext()
  const notificationsReadLastTime = new Date(notificationsSeen?.lastSeen ?? '2036-01-01') // do not show new notifications by default
  const unreadNotifications =
    Notifications.filter(({ scope }) => scope.includes(functionArea)).filter(n =>
      isAfter(n.date, notificationsReadLastTime)
    ).length > 0

  return (
    <>
      <div className="TopBar">
        <Navigation />
        <FunctionSelector page={currentPage} />

        <LocationSearch functionArea={functionArea} onLocationSelected={onLocationSelected} />

        <div className="ExploreButton">{exploreButton}</div>

        {useInFlexLayout && <div style={{ flexGrow: 1 }} />}

        <div className="NotificationsAndFeedback">
          <div className="GiveFeedbackLink" onClick={() => openFeedbackModal()}>
            <Tooltip className="TopBarTooltip" tooltipText="Give feedback" position="bottom">
              <FeedbackIcon />
            </Tooltip>
          </div>
          {Notifications.length > 0 && (
            <div
              className={classNames('Notification', { Unread: unreadNotifications })}
              onClick={() => {
                if (!areNotificationsOpen) {
                  analyticsEvent({
                    category: 'Notifications',
                    action: 'open',
                    label: 'modal'
                  })
                }
                setNotificationsOpen(!areNotificationsOpen)
              }}
            >
              <Tooltip
                className="TopBarTooltip"
                tooltipText={unreadNotifications ? 'You have unread notifications' : 'Notifications'}
                position="bottom"
              >
                <NotificationIcon />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {isFeedbackOpen && <FeedbackModal isOpen={isFeedbackOpen} closeFn={() => closeFeedbackModal()} />}
      {areNotificationsOpen && (
        <NotificationsModal isOpen={areNotificationsOpen} closeFn={() => setNotificationsOpen(false)} />
      )}
    </>
  )
}

interface FunctionSelectorProps {
  page: string
}

const FunctionSelector: React.FC<FunctionSelectorProps> = ({ page }) => {
  const [{ func, functionArea }, updateSharedSelections] = useSharedSelections()
  const { currentLocation } = useLocations()

  const defaults = {
    disabled: getLocationId(currentLocation).length > 3 && functionArea !== 'centres',
    options:
      functionArea === 'ingka'
        ? functionList
        : functionArea === 'retail'
        ? functionList.filter(({ id }) => id === 'retail')
        : functionList.filter(({ id }) => id === 'centres'),
    selected: func
  }

  const onlyStores = {
    options: defaults.options.map(option => ({
      ...option,
      disabled: !['ALL', 'Retail (All)'].includes(option.value),
      children:
        option.type === 'with-children'
          ? option.children.map(child => ({
              ...child,
              disabled: !['Retail'].includes(child.value)
            }))
          : []
    })),
    selected: defaults.selected.filter(selection => selection === 'ALL' || selection === 'Retail')
  }

  const overrides: Record<string, Partial<typeof defaults>> = {
    [Route.PppSalesKPIPage]: {
      disabled: true,
      selected: ['Retail']
    },
    [Route.CustomerDeliveriesKPIPage]: {
      disabled: true,
      selected: ['Retail']
    },
    [Route.FoodIngredientsKPIPage]: {
      disabled: true,
      selected: ['Retail']
    },
    [Route.SustainabilityMentionsKPIPage]: {
      disabled: true,
      options: [{ type: 'without-children', id: 'ingka', value: 'ALL', label: 'All Functions', children: [] }],
      selected: ['ALL']
    },
    [Route.ProfitGrowthPageDeprecated]: {
      ...defaults,
      ...onlyStores
    },
    [Route.SocialImpactKPIPage]: {
      disabled: true,
      selected:
        functionArea === 'ingka' ? ['ALL'] : functionArea === 'retail' ? retailSiteFunctions : centreSiteFunctions
    },
    [Route.ChangeMakersKPIPage]: {
      disabled: true,
      selected:
        functionArea === 'ingka' ? ['ALL'] : functionArea === 'retail' ? retailSiteFunctions : centreSiteFunctions
    },
    [Route.CircularRevenueKPIPage]: {
      disabled: true,
      selected: ['Retail']
    }
  }

  return (
    <MultiSelectionMenu
      name="KPIFunctionSelector"
      onChange={func => {
        if (functionArea === 'retail' && isEqual(func, ['ALL'])) {
          updateSharedSelections({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            func: functionList.find(({ id }) => id === 'retail')!.children.map(({ value }) => value)
          })
        } else if (functionArea === 'centres' && isEqual(func, ['ALL'])) {
          updateSharedSelections({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            func: functionList.find(({ id }) => id === 'centres')!.children.map(({ value }) => value)
          })
        } else {
          updateSharedSelections({ func: func as SiteFunction[] })
        }
      }}
      {...(page in overrides ? { ...defaults, ...overrides[page] } : defaults)}
    />
  )
}
