import { format } from 'date-fns'
import React, { Fragment } from 'react'
import classNames from 'classnames'

import {
  CountryCode,
  EnergyEfficiencyRolling,
  WaterEfficiencyBenchmark,
  WaterSmallCardData
} from '../../../../api/src/common-types'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, multiFormatNumber } from '../../../components/Utils/format'
import {
  getWaterEfficiency,
  getWaterEfficiencyBenchmarking,
  getWaterEfficiencySmallGraphData
} from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import { getCountry, getLocationId, getLocationLabel, isSiteId } from '../../../components/Utils/utils'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  MainCard,
  RenderBenchmarkType,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useDataAvailabilityContext, useLocations } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'
import { formatEnergyEfficiencySeries } from '../EnergyEfficiency/EnergyEfficiencyKPIPage'
import { Link } from '../../../components/Link'
import Button from '@ingka/button'
import { Dictionary } from 'lodash'
import _ from 'lodash'
import { WaterGraphSideBarCards } from '../../../components/GraphSideBarCards'
import ZeroGraph from '../../../components/ZeroGraph'

export const WaterEfficiencyKPIPage = () => {
  useDocumentTitle('Water Efficiency')

  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, locations } = useLocations()
  const [footprint, setFootprint] = React.useState<EnergyEfficiencyRolling[]>()
  const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarks, setBenchmarks] = React.useState<WaterEfficiencyBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const [graphData, setGraphData] = React.useState<Dictionary<WaterSmallCardData[]>>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const page = Route.WaterEfficiencyKPIPage

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setFootprint(undefined)
    setBenchmarks(undefined)
    setLastUpdated('')
    setIsLoading(true)

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getWaterEfficiencyBenchmarking(countryCode, func).then(benchmarks => setBenchmarks(benchmarks))
    getWaterEfficiency(locationId, func, currentFY).then(result => {
      setFootprint(result.data.map(f => ({ ...f, fiscalYear: f.fiscalYear - 2000 })))
      setDates(result.dates.map(d => new Date(d)))
      result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
    })
    getWaterEfficiencySmallGraphData(locationId, func).then(data => {
      setGraphData(_.groupBy(data, d => `FY${d.fiscalYear}`))
      setIsLoading(false)
    })
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const countryBenchmark: WaterEfficiencyBenchmark = React.useMemo(() => {
    return (
      benchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
        id: getLocationId(currentLocation),
        label: getLocationLabel(currentLocation),
        area: 0,
        goalPrevFY: 0,
        goal: 0,
        goalNextFY: 0,
        selectable: true,
        previousYtd: 0,
        previousFy: 0,
        currentYtd: 0,
        readableDate: '',
        fiscalYear: 0,
        visitorsCurrentYtd: 0,
        waterCurrentYtd: 0,
        visitorsPreviousYtd: 0,
        waterPreviousYtd: 0,
        rolling: 0
      }
    )
  }, [JSON.stringify(currentLocation), JSON.stringify(benchmarks)])

  const [selectedLocationBenchmark, comparisonBenchmarks] = React.useMemo(() => {
    const locationId = getLocationId(currentLocation)
    if (benchmarks === undefined) {
      return [undefined, []]
    }
    const selectedLocationBenchmark = isSiteId(locationId)
      ? benchmarks.find(b => b.id === locationId)
      : countryBenchmark

    return [
      selectedLocationBenchmark,
      benchmarks.filter(b => b.id !== locationId).slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [JSON.stringify(benchmarks)])

  const benchmarksWithoutCurrentLocation = React.useMemo(() => {
    const isSite = isSiteId(locationId)
    return benchmarks?.filter(b => b.id !== locationId && (isSite ? b.id?.length >= 3 : true)) ?? []
  }, [JSON.stringify(benchmarks)])

  const renderBenchmark: RenderBenchmarkType<WaterEfficiencyBenchmark> = (benchmark, keys, classes) => {
    const prevFYValue = benchmark[keys[0]]
    const currFYValue = benchmark[keys[1]]
    const goalPY = benchmark[keys[2]]
    const goal = benchmark[keys[3]]
    const goalNY = benchmark[keys[4]]
    const trackClass =
      keys[1] !== 'currentYtd' || !benchmark.goal || !benchmark[keys[1]]
        ? 'Black'
        : !benchmark.goal || benchmark[keys[1]] < benchmark.goal
        ? 'OnTrack'
        : 'YTD'

    return (
      <Fragment key={benchmark.id}>
        <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
        <div className={classNames('Right', classes)}>{multiFormatNumber(prevFYValue)}</div>
        <div className={classNames('Right', classes, trackClass)}>{multiFormatNumber(currFYValue)}</div>
        <div className={classNames('Right', classes)}>
          {!isSiteId(benchmark.id) && func.includes('ALL') ? 'N/A' : multiFormatNumber(goalPY)}
        </div>
        <div className={classNames('Right', classes)}>
          {!isSiteId(benchmark.id) && func.includes('ALL') ? 'N/A' : multiFormatNumber(goal)}
        </div>
        <div className={classNames('Right', classes)}>
          {!isSiteId(benchmark.id) && func.includes('ALL') ? 'N/A' : multiFormatNumber(goalNY)}
        </div>
        <div />
      </Fragment>
    )
  }

  const benchmarkHeaders: HeadingItem<WaterEfficiencyBenchmark>[] = [
    [
      {
        name: `FY${currentFYShort - 1} YTD`,
        key: 'previousYtd'
      },
      { name: `FY${currentFYShort - 1} Result`, key: 'previousFy' }
    ],
    [
      { name: `FY${currentFYShort} YTD`, key: 'currentYtd' },
      { name: 'Rolling 12 mos', key: 'rolling' }
    ],
    [{ name: `FY${currentFYShort - 1} Goal`, key: 'goalPrevFY' }],
    [{ name: `FY${currentFYShort} Goal`, key: 'goal' }],
    [{ name: `FY${currentFYShort + 1} Goal`, key: 'goalNextFY' }]
  ]

  const ytdSummary = selectedLocationBenchmark?.rolling ? (
    <span>
      {selectedLocationBenchmark.currentYtd?.toFixed(1)} <span className="Label">liter/visitor YTD </span>
    </span>
  ) : (
    <span>
      0 <span className="Label">liter/visitor YTD </span>
    </span>
  )

  const exploreButton = (
    <Link page={Route.WaterExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.WaterEfficiencyKPIPage} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader className="ClimateFootprintHeader" route={Route.WaterEfficiencyKPIPage} />
      <div className="PageContent">
        <Stripe title="Water Efficiency">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        {benchmarks?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                key="benchmarking"
                benchmarks={
                  comparisonBenchmarks
                    ? [...(selectedLocationBenchmark ? [selectedLocationBenchmark] : []), ...comparisonBenchmarks]
                    : []
                }
                label="litre/visitor"
                headers={benchmarkHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                renderBenchmark={renderBenchmark}
                totalLocations={benchmarksWithoutCurrentLocation.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['YTD']}
                kpis={
                  benchmarks?.length === 0 && !selectedLocationBenchmark
                    ? []
                    : [
                        {
                          key: 'Total Visitors',
                          unit: '',
                          value: selectedLocationBenchmark?.visitorsCurrentYtd
                            ? formatAbsoluteNumber(selectedLocationBenchmark?.visitorsCurrentYtd)
                            : ''
                        },
                        {
                          key: 'Total Water',
                          unit: 'litres',
                          value: selectedLocationBenchmark?.waterCurrentYtd
                            ? formatAbsoluteNumber(selectedLocationBenchmark?.waterCurrentYtd)
                            : ''
                        },
                        {
                          key: 'Water Efficiency ',
                          unit: 'litre/visitor',
                          value: selectedLocationBenchmark?.currentYtd?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          isUnitRegular: true,
                          colorClass: selectedLocationBenchmark?.goal
                            ? selectedLocationBenchmark?.goal >= (selectedLocationBenchmark?.currentYtd ?? 0)
                              ? GoalStatus.OnTrack
                              : GoalStatus.NotOnTrack
                            : ''
                        },
                        {
                          key: `FY${(dataAvailability?.waterCurrentFY || 2024) - 2000} Goal`,
                          unit: 'litre/visitor',
                          value: selectedLocationBenchmark?.goal?.toFixed(1) ?? 'N/A',
                          isUnitRegular: true,
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold']
                        }
                      ]
                }
              />
            </CardRow>
            <MainCard
              title="Water Efficiency"
              description="Water Efficiency = total water consumption / visitors"
              subtitle={ytdSummary}
              coWorkers
            >
              {(formatEnergyEfficiencySeries(footprint, dates) ?? []).length > 0 ? (
                <div className="GraphContainer">
                  <ChartContainer
                    domain={dates}
                    series={formatEnergyEfficiencySeries(footprint, dates, selectedLocationBenchmark?.goal)}
                    generator={lineChart}
                    dateFormat="month"
                    lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
                    showDecimals
                  />
                </div>
              ) : (
                dates && <ZeroGraph domain={dates} fy={currentFYShort} />
              )}
            </MainCard>
          </>
        )}
        <WaterGraphSideBarCards
          className={classNames('GraphRow', 'three-in-row')}
          graphData={graphData}
          isLoading={isLoading}
          isSite={isSiteId(locationId)}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks && benchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarksWithoutCurrentLocation}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={benchmarkHeaders}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          renderBenchmark={renderBenchmark}
          sortBy="label"
          sortDirection="asc"
          title="water efficiency litre/visitor"
        />
      )}
    </div>
  )
}
