import React from 'react'
import { BenchmarkingContext, CurrentFyTimeRange, PreviousFyTimeRange } from '../Provider'
import {
  emptyResult,
  emptyGoal,
  formatToPercentagesInit,
  sumZeroWaste,
  useBenchmarkHelperData,
  sortByLocationName
} from './helpers'
import {
  BenchmarkBase,
  BenchmarkTimeRanges,
  ClimateResultsBenchmark,
  EnergyEfficiencyBenchmark,
  FoodIngredientsBenchmark,
  PlanetBenchmarkResult,
  WaterEfficiencyBenchmark
} from '../../../../../api/src/types/benchmark'
import { CountryGoals, GoalsResponse, SiteGoals } from '../../../../../api/src/types/goals'
import { curry } from 'lodash'
import { getGoalsForLocation, getLocationOrDefault, isSiteId } from '../../../../components/Utils/utils'
import { useDataAvailabilityContext } from '../../../../context'
import { ZeroWasteBenchmark } from '../../../KPIPages/ZeroWaste/ZeroWasteKPIPage'
import { LocationResults } from '../../../../components/Benchmark/SiteResultsTable'

const getEnergyEfficiencyResult = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  result?: EnergyEfficiencyBenchmark | null,
  goals?: GoalsResponse
) => {
  if (!result) {
    return emptyResult
  }
  const goal = getGoalsForLocation(result.id, goals) || emptyGoal

  return {
    selectable: true,
    locationName: result.label,
    comparisonValue: currTimeRange === 'YTD' ? result.currentYtd : result.rolling,
    previousFy: prevTimeRange === 'YTD' ? result.previousYtd : result.previousFy,
    goal: goal.energyEfficiencyGoal || null,
    goalNextFy: goal.energyEfficiencyGoalNextFY || null,
    goalPrevFy: goal.energyEfficiencyGoalPrevFY || null
  }
}

const getWaterEfficiencyResult = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  result?: WaterEfficiencyBenchmark | null
) => {
  if (!result) {
    return emptyResult
  }

  return {
    selectable: true,
    locationName: result.label,
    comparisonValue: currTimeRange === 'YTD' ? result.currentYtd : result.rolling,
    previousFy: prevTimeRange === 'YTD' ? result.previousYtd : result.previousFy,
    goal: result.goal,
    goalNextFy: result.goalNextFY,
    goalPrevFy: result.goalPrevFY
  }
}

const getFoodIngredientsResult = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  result?: FoodIngredientsBenchmark | null
) => {
  if (!result) {
    return emptyResult
  }

  return {
    selectable: true,
    locationName: result.label,
    comparisonValue: currTimeRange === 'YTD' ? result.currentFYTotalFootprint : null,
    previousFy: prevTimeRange === 'YTD' ? result.previousYTDTotalFootprint : result.previousFYTotalFootprint,
    goal: result.goal / 1000,
    goalNextFy: result.goalNextFY / 1000 || null,
    goalPrevFy: result.goalPrevFY / 1000 || null
  }
}

const getCustomerDeliveriesResult = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  fiscalPeriod?: number,
  result?: ClimateResultsBenchmark | null,
  goals?: GoalsResponse
) => {
  if (!result) {
    return emptyResult
  }

  const goal = getGoalsForLocation(result.id, goals) || emptyGoal

  return {
    selectable: true,
    locationName: result.label,
    comparisonValue: currTimeRange === 'YTD' ? result.currentYtd : result.rolling,
    previousFy: prevTimeRange === 'YTD' ? result.previousYtd : result.previousFy,
    goal: goal.deliveriesZeroEmissionGoal ? goal.deliveriesZeroEmissionGoal * 100 : null,
    goalNextFy: goal.deliveriesZeroEmissionGoalNextFY ? goal.deliveriesZeroEmissionGoalNextFY * 100 : null,
    goalPrevFy: goal.deliveriesZeroEmissionGoalPrevFY ? goal.deliveriesZeroEmissionGoalPrevFY * 100 : null,
    fiscalPeriod: fiscalPeriod
  }
}

const getPlanetResult = (
  numerator: Exclude<keyof PlanetBenchmarkResult, keyof BenchmarkBase>,
  denominator: Exclude<keyof PlanetBenchmarkResult, keyof BenchmarkBase>,
  goalKey: keyof CountryGoals | keyof SiteGoals,
  goalKeyNext: keyof CountryGoals | keyof SiteGoals,
  goalKeyPrev: keyof CountryGoals | keyof SiteGoals,
  isPlanet: boolean
) =>
  curry(
    (
      prevTimeRange: PreviousFyTimeRange,
      currTimeRange: CurrentFyTimeRange,
      isInPreviousFy: boolean,
      goals: GoalsResponse | undefined,
      result: BenchmarkTimeRanges<PlanetBenchmarkResult>
    ): LocationResults => {
      const goal = {
        ...emptyGoal,
        ...getGoalsForLocation(result.ytd.id, goals)
      }
      return {
        selectable: true,
        locationName: result.ytd.name,
        comparisonValue:
          currTimeRange === 'YTD' && isInPreviousFy
            ? null
            : currTimeRange === 'YTD' && !isInPreviousFy
            ? result.ytd[numerator] / result.ytd[denominator]
            : result.rolling12?.[numerator] / result.rolling12?.[denominator],
        previousFy:
          prevTimeRange === 'YTD' && isInPreviousFy
            ? result.ytd[numerator] / result.ytd[denominator]
            : prevTimeRange === 'YTD' && !isInPreviousFy
            ? result.previousFyYtd?.[numerator] / result.previousFyYtd?.[denominator]
            : prevTimeRange === 'Result' && isInPreviousFy
            ? result.ytd[numerator] / result.ytd[denominator]
            : result.previousFy?.[numerator] / result.previousFy?.[denominator],
        goal: goalKey && isPlanet ? (Number(goal[goalKey]) * 1000) / result.ytd.area_m2 : Number(goal[goalKey]),
        goalNextFy:
          goalKeyNext && isPlanet ? (Number(goal[goalKeyNext]) * 1000) / result.ytd.area_m2 : Number(goal[goalKeyNext]),
        goalPrevFy:
          goalKeyPrev && isPlanet ? (Number(goal[goalKeyPrev]) * 1000) / result.ytd.area_m2 : Number(goal[goalKeyPrev])
      }
    }
  )

const getZeroWaste = (
  prevTimeRange: PreviousFyTimeRange,
  currTimeRange: CurrentFyTimeRange,
  isInPreviousFy: boolean,
  goal?: CountryGoals | SiteGoals,
  result?: ZeroWasteBenchmark
) => {
  if (!result) {
    return emptyResult
  }

  return {
    selectable: true,
    locationName: result.label,
    comparisonValue:
      isInPreviousFy && currTimeRange === 'YTD' ? null : currTimeRange === 'YTD' ? result.currentYtd : result.rolling12,
    previousFy:
      isInPreviousFy && prevTimeRange === 'YTD'
        ? result.currentYtd
        : prevTimeRange === 'YTD'
        ? result.previousYtd
        : result.previousFyResult,
    goal: goal?.zeroWasteGoal ? goal.zeroWasteGoal : null,
    goalNextFy: goal?.zeroWasteGoalNextFY ? goal.zeroWasteGoalNextFY : null,
    goalPrevFy: goal?.zeroWasteGoalPrevFY ? goal.zeroWasteGoalPrevFY : null
  }
}

const getFootprintByArea = getPlanetResult(
  'total_footprint',
  'area_m2',
  'climateFootprintGoal',
  'climateFootprintGoalNextFY',
  'climateFootprintGoalPrevFY',
  true
)
const getRecyclingRate = getPlanetResult(
  'recycled_waste',
  'total_waste',
  'recyclingRateGoal',
  'recyclingRateGoalNextFY',
  'recyclingRateGoalPrevFY',
  false
)

export const useClimateData = () => {
  const context = React.useContext(BenchmarkingContext)
  const { dataAvailability } = useDataAvailabilityContext()
  const { currFy } = useBenchmarkHelperData()
  const locationId = getLocationOrDefault()

  if (!context) return null

  const {
    prevTimeRange,
    currTimeRange,
    goals,
    planet,
    zeroWaste,
    energyEfficiency,
    waterEfficiency,
    foodIngredients,
    customerDeliveries
  } = context.data

  const isInPreviousFy = (dataAvailability?.planetCurrentFY ?? -1) < currFy
  const childLocations = planet && planet.status === 'ok' ? Object.values(planet.childLocations).filter(x => x.ytd) : []

  const climateData = !planet
    ? null
    : {
        locationResult:
          planet.status === 'ok'
            ? getFootprintByArea(prevTimeRange, currTimeRange, isInPreviousFy, goals, planet.selectedLocation)
            : emptyResult,
        childLocationResults: childLocations
          .map(getFootprintByArea(prevTimeRange, currTimeRange, isInPreviousFy, goals))
          .sort(sortByLocationName)
      }

  const recyclingRateData = !planet
    ? null
    : {
        locationResult:
          planet.status === 'ok'
            ? formatToPercentagesInit(true)(
                getRecyclingRate(prevTimeRange, currTimeRange, isInPreviousFy, goals, planet.selectedLocation)
              )
            : emptyResult,
        childLocationResults: childLocations
          .map(getRecyclingRate(prevTimeRange, currTimeRange, isInPreviousFy, goals))
          .map(formatToPercentagesInit(true))
          .sort(sortByLocationName)
      }

  const energyEfficiencyData = !energyEfficiency
    ? null
    : {
        locationResult: getEnergyEfficiencyResult(
          prevTimeRange,
          currTimeRange,
          energyEfficiency.find(item => item.id === locationId),
          goals
        ),
        childLocationResults: (energyEfficiency || []).map(item =>
          getEnergyEfficiencyResult(prevTimeRange, currTimeRange, item, goals)
        )
      }

  const waterEfficiencyData = !waterEfficiency
    ? null
    : {
        locationResult: getWaterEfficiencyResult(
          prevTimeRange,
          currTimeRange,
          waterEfficiency.find(item => item.id === locationId)
        ),
        childLocationResults: (waterEfficiency || []).map(item =>
          getWaterEfficiencyResult(prevTimeRange, currTimeRange, item)
        )
      }

  const zeroWasteData = !zeroWaste
    ? null
    : {
        locationResults: getZeroWaste(
          prevTimeRange,
          currTimeRange,
          (dataAvailability?.planetCurrentFY ?? -1) < currFy,
          getGoalsForLocation(locationId, goals),
          isSiteId(locationId)
            ? zeroWaste?.find(x => x.id === locationId)
            : zeroWaste.reduce(sumZeroWaste, {
                currentYtd: 0,
                previousYtd: 0,
                previousFyResult: 0,
                rolling12: 0,
                id: '',
                label: '',
                selectable: false
              })
        ),
        childLocationResults:
          zeroWaste
            ?.filter(x => x.id !== locationId && x.selectable)
            .map(x =>
              getZeroWaste(
                prevTimeRange,
                currTimeRange,
                (dataAvailability?.planetCurrentFY ?? -1) < currFy,
                getGoalsForLocation(x.id, goals),
                x
              )
            )
            .sort(sortByLocationName) ?? []
      }

  const foodIngredientsData = !foodIngredients
    ? null
    : {
        locationResult: getFoodIngredientsResult(
          prevTimeRange,
          currTimeRange,
          foodIngredients.find(item => item.id === locationId)
        ),
        childLocationResults: foodIngredients.map(item => getFoodIngredientsResult(prevTimeRange, currTimeRange, item))
      }

  const customerDeliveriesData = !customerDeliveries
    ? null
    : {
        locationResult: getCustomerDeliveriesResult(
          prevTimeRange,
          currTimeRange,
          customerDeliveries.find(item => item.id === locationId)?.fiscalPeriod,
          customerDeliveries.find(item => item.id === locationId),
          goals
        ),
        childLocationResults: customerDeliveries.map(item =>
          getCustomerDeliveriesResult(prevTimeRange, currTimeRange, item.fiscalPeriod, item, goals)
        )
      }

  return {
    climateData,
    zeroWasteData,
    recyclingRateData,
    energyEfficiencyData,
    waterEfficiencyData,
    foodIngredientsData,
    customerDeliveriesData
  }
}
