import React from 'react'
import { flatMap, flatten, groupBy, uniq } from 'lodash'
import { scaleLinear } from 'd3-scale'
import { stack as d3Stack, stackOffsetNone, stackOrderAppearance, stackOrderNone } from 'd3-shape'
import classNames from 'classnames'
import { format as formatDate, addYears } from 'date-fns'

import { ChartTooltip, TooltipPosition, TooltipProps, TooltipSettings } from './Tooltip'

import './ChartContainer.scss'
import './SimpleBar.scss'
import colours from '../../Colours.module.scss'

import { calculateVerticalGuides } from './GraphUtil'
import { formatChangePercentage } from '../Utils/format'
import { useInStoreContext } from '../../context'
import { useResizableSVG } from './ChartContainer'

interface Overlay {
  value: number
  color: string
  overlayBarColor?: string
  tick?: {
    value: number
    color: string
  }
}

interface TooltipPropsAndPositionProps {
  tooltipProps?: TooltipProps
  tooltipPosition: TooltipPosition
  graphWidth?: number
}

type DataPointKeyType = string | Date
export interface DataPoint {
  name: DataPointKeyType
  value: number
  overlay?: Overlay
  createElement?: () => JSX.Element
}

interface ColorDef {
  color: string
  opacity?: number
}

export interface SimpleBarSerie {
  name: string
  color: string | Record<string, ColorDef>
  data: DataPoint[]
}

type DateFormat = 'week' | 'monthWithYear' | 'fy' | 'month'

interface SimpleBarProps {
  series: SimpleBarSerie[]
  tooltipFn?: (index: number) => TooltipProps
  className?: string
  stacked: boolean
  withChange?: boolean
  xAxisTitle?: string
  yAxisTitle?: string
  onSerieClick?: (name: string) => void
  longSerieNames?: boolean
  maxValue?: number
  largeBars?: boolean
  testId?: string
  showIfNaN?: boolean
  isModal?: boolean
}

const margin = 10
const defaultHorizontalBarHeight = 16
const defaultHorizontalBarGap = 12

const horizontalBarHeight = {
  default: 16,
  large: 28,
  inStore: 32
}

const horizontalBarGap = {
  default: 12,
  large: 28,
  inStore: 36
}
type SizingKey = keyof typeof horizontalBarGap

export const SimpleBar = (props: SimpleBarProps) => {
  const {
    series,
    tooltipFn,
    className,
    stacked,
    withChange,
    xAxisTitle,
    yAxisTitle,
    onSerieClick,
    longSerieNames,
    maxValue,
    largeBars,
    testId,
    showIfNaN
  } = props
  const [tooltipPropsAndPosition, setTooltipPropsAndPosition] = React.useState<
    TooltipPropsAndPositionProps | undefined
  >(undefined)

  const svgRef = React.useRef<SVGSVGElement>(null)
  const { graphWidth, graphHeight, valueLabelWidth } = useResizableSVG(svgRef)
  const isInStore = useInStoreContext()
  const sizingKey = isInStore ? 'inStore' : largeBars ? 'large' : 'default'

  let bars: React.ReactNode
  let horizontalBarCount: number | undefined = undefined
  if (stacked) {
    const result = generateStackedHorizontalBars(series, graphWidth, graphHeight, (graphWidth / 9) * 2, valueLabelWidth)
    bars = result.elements
    horizontalBarCount = result.barCount
  } else {
    const result = generateHorizontalBarsWithChange(
      series,
      graphWidth,
      graphHeight,
      longSerieNames ? graphWidth / 3.3 : (graphWidth / 9) * 2,
      valueLabelWidth,
      sizingKey,
      xAxisTitle,
      maxValue,
      withChange,
      onSerieClick,
      showIfNaN
    )
    bars = result.elements
    horizontalBarCount = result.barCount
  }

  const mouseMoved = (event: React.MouseEvent) => {
    if (tooltipFn == null) {
      return
    }
    const index = (event.target as SVGElement).getAttribute('data-index')
    if (index == null) {
      setTooltipPropsAndPosition(undefined)
      return
    }
    const ttProps = tooltipFn(parseInt(index))
    setTooltipPropsAndPosition({ tooltipProps: ttProps, tooltipPosition: { x: event.clientX, y: event.clientY } })
  }

  const svgHeight = horizontalBarCount
    ? `${horizontalBarCount * (horizontalBarHeight[sizingKey] + horizontalBarGap[sizingKey])}px`
    : undefined

  return (
    <div className="ChartContainer">
      <div className="ChartWithYTitle">
        {yAxisTitle && (
          <div className="YAxisTitle">
            <span>{yAxisTitle}</span>
          </div>
        )}
        <svg
          data-testid={testId}
          ref={svgRef}
          className={classNames(className, 'Chart')}
          onMouseMove={ev => mouseMoved(ev)}
          height={svgHeight}
        >
          {bars}
        </svg>
      </div>
      {tooltipPropsAndPosition && (
        <ChartTooltip
          x={tooltipPropsAndPosition?.tooltipPosition.x}
          y={tooltipPropsAndPosition?.tooltipPosition.y}
          data={{ tooltipProps: tooltipPropsAndPosition.tooltipProps as TooltipProps }}
        />
      )}
    </div>
  )
}

function generateHorizontalBarsWithChange(
  series: SimpleBarSerie[],
  graphWidth: number,
  graphHeight: number,
  textWidth: number,
  maxValueLength: number,
  sizingKey: SizingKey,
  xAxisTitle?: string,
  fixedMaxValue?: number,
  withChange?: boolean,
  onSerieClick?: (name: string) => void,
  showIfNaN?: boolean
) {
  const marginTop = sizingKey === 'default' ? 13 : 26
  const curveWidth = 5
  const maxValue = fixedMaxValue || max(series)
  const overlayHeight = horizontalBarHeight[sizingKey] + (sizingKey === 'default' ? 9 : 16)

  const calculateX = scaleLinear()
    .domain([0, maxValue])
    .rangeRound([textWidth, graphWidth - maxValueLength])

  const calculateChange = (d: DataPoint) => {
    if (d.overlay && withChange) {
      return !showIfNaN || Number.isFinite(d.value) ? d.value / (d.overlay?.value ?? 1) - 1 : -1
    }
    return d.value
  }

  const zeroX = 350
  const bars = flatMap(series, serie => {
    return serie.data.map((d, i) => {
      const y = i * (horizontalBarHeight[sizingKey] + horizontalBarGap[sizingKey]) + marginTop
      const textBaseline = y + horizontalBarHeight[sizingKey] / 2 + 6
      const text = formatValue(d.name)
      const color = parseColor(serie.color, text)
      const overlayBarColor = d?.overlay?.overlayBarColor ? parseColor(d.overlay.overlayBarColor) : null
      const barWidth = Math.max(calculateX(d.value) - zeroX - curveWidth, d.value > 0 ? curveWidth : 0)
      const overlayWidth = d.overlay ? calculateX(d.overlay.value) : 0
      const tickWidth = d.overlay?.tick ? calculateX(d.overlay.tick.value) : 0
      const change = calculateChange(d)
      return (
        <g key={`${d.name}Bar`}>
          <foreignObject
            x={0}
            y={y}
            width={300}
            height={horizontalBarHeight[sizingKey] + 2 * curveWidth}
            onClick={_ => onSerieClick && onSerieClick(d.name as string)}
          >
            <div className="YAxisText">{d.createElement ? d.createElement() : text}</div>
          </foreignObject>
          {d.overlay && change < 0 && !isNaN(overlayWidth - zeroX - curveWidth) ? (
            <path
              className="NegativeChangeOverlay"
              data-index={i}
              d={`M${zeroX},${y} h${overlayWidth - zeroX - curveWidth} q${curveWidth},0 ${curveWidth},${curveWidth} v${
                horizontalBarHeight[sizingKey] - curveWidth
              } q0,5 -${curveWidth},${curveWidth} h${-overlayWidth + zeroX + curveWidth} z`}
              fill={overlayBarColor ? overlayBarColor.color : color.color}
              fillOpacity={overlayBarColor ? 1 : 0.5}
            />
          ) : null}
          {barWidth > 0 && (
            <path
              data-index={i}
              d={`M${zeroX},${y} h${barWidth} q${curveWidth},0 ${curveWidth},5 v${
                horizontalBarHeight[sizingKey] - 5
              } q0,5 -${curveWidth},5 h${-barWidth} z`}
              fill={color.color}
              fillOpacity={color.opacity}
            />
          )}
          {(Number.isFinite(d.value) || showIfNaN) && d.overlay && Number.isFinite(d.overlay.value) && (
            <rect
              data-index={i}
              width={sizingKey === 'default' ? 2 : 4}
              height={overlayHeight}
              x={overlayWidth}
              y={y - (sizingKey === 'default' ? 2 : 5)}
              fill={d.overlay.color}
            />
          )}
          {(Number.isFinite(d.value) || showIfNaN) && d.overlay?.tick && (
            <>
              <rect
                data-index={i}
                width={sizingKey === 'default' ? 2 : 4}
                height={overlayHeight}
                x={tickWidth}
                y={y - (sizingKey === 'default' ? 2 : 5)}
                fill={d.overlay.tick.color ? d.overlay.tick.color : colours.black}
              />
              {showIfNaN && !Number.isFinite(d.value) && (
                <rect
                  data-index={i}
                  width={Math.min(tickWidth * 1.2, 600)}
                  height={overlayHeight}
                  x="385"
                  y={y - (sizingKey === 'default' ? 2 : 5)}
                  fill="white"
                  fillOpacity={0}
                />
              )}
            </>
          )}
          {Number.isFinite(d.value) ? (
            <text
              className={`HorizontalBarChartValue ${change > 0 ? 'OnTrack' : 'NotOnTrack'}`}
              x={`${
                Math.max(zeroX + barWidth + curveWidth, overlayWidth - zeroX - curveWidth, overlayWidth, tickWidth) +
                margin
              }px`}
              y={textBaseline}
              fontSize="12px"
            >
              {withChange ? formatChangePercentage(change) : `${change.toFixed(1)}%`}
            </text>
          ) : !showIfNaN ? (
            <text x={`${zeroX}px`} y={textBaseline}>
              N/A
            </text>
          ) : null}
        </g>
      )
    })
  })

  return {
    elements: (
      <>
        {xAxisTitle && (
          <text
            className="XAxisLabel"
            x={calculateX(maxValue / 2)}
            y={-16}
            fontSize="10px"
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {xAxisTitle}
          </text>
        )}
        {calculateVerticalGuides(graphHeight, maxValue, calculateX)}
        {bars}
      </>
    ),
    barCount: bars.length
  }
}

function generateStackedHorizontalBars(
  series: SimpleBarSerie[],
  graphWidth: number,
  graphHeight: number,
  textWidth: number,
  maxValueLength: number
) {
  const { stackSeries, maxValues } = generateStack(series, true)
  const maxValue = Math.max(...maxValues.filter(Number.isFinite))
  const marginTop = 13

  const calculateX = scaleLinear()
    .domain([0, maxValue])
    .rangeRound([textWidth, graphWidth - maxValueLength])

  const serieTitles = uniq(flatMap(series, serie => serie.data.map(d => d.name))).map((title, i) => (
    <foreignObject
      key={i}
      x={0}
      y={i * (defaultHorizontalBarHeight + defaultHorizontalBarGap) + marginTop}
      width={textWidth}
      height={defaultHorizontalBarHeight + 10}
    >
      <div className="YAxisText">{title}</div>
    </foreignObject>
  ))

  const bars = stackSeries.map((stack, i) => {
    const serie = series.find(s => s.name === stack.key)
    return stack.map((datapoint, j) => {
      const color = parseColor(serie?.color ?? 'black')
      const barWidth = calculateX(datapoint[1]) - calculateX(datapoint[0])
      const y = j * (defaultHorizontalBarHeight + defaultHorizontalBarGap) + marginTop
      const isMaxValue = datapoint[1] === maxValues[j]
      const radius = isMaxValue && barWidth > 0 ? 5 : 0
      return (
        <g key={`${stack.key}${i}${j}`}>
          <path
            data-index={j}
            d={`M${calculateX(datapoint[0])},${y} h${Math.max(
              barWidth - radius,
              0
            )} q${radius},0 ${radius},${radius} v${
              defaultHorizontalBarHeight - (radius === 0 ? -5 : radius)
            } q0,${radius} -${radius},${radius} h-${Math.max(barWidth - radius, 0)} z`}
            fill={color.color}
            fillOpacity={color.opacity}
          />
          {isMaxValue && datapoint[1] > 0 && (
            <text
              className="HorizontalBarChartValue"
              x={calculateX(datapoint[1]) + 4}
              y={y + defaultHorizontalBarHeight / 2 + 6}
              fontSize="12px"
            >
              {datapoint[1]}
            </text>
          )}
        </g>
      )
    })
  })

  return {
    elements: (
      <>
        {calculateVerticalGuides(graphHeight, maxValue, calculateX)}
        {serieTitles}
        {bars}
      </>
    ),
    barCount: bars[0]?.length ?? 0
  }
}

function generateStack(series: SimpleBarSerie[], ascending: boolean) {
  const keys = series.map(s => s.name)
  const groups = groupBy(
    flatMap(series, s => s.data.map(d => ({ name: d.name, color: s.color, seriesId: s.name, value: d.value }))),
    'name'
  )
  const dataByGroup = Object.keys(groups).map(key => {
    const data = groups[key]
    return keys.reduce((res, key) => {
      const serie = data.find(d => d.seriesId === key)
      return {
        ...res,
        [key]: serie?.value,
        name: serie?.name,
        color: serie?.color
      }
    }, {})
  })

  const stackGenerator = d3Stack()
    .keys(keys)
    .order(ascending ? stackOrderAppearance : stackOrderNone)
    .offset(stackOffsetNone)
  const stackSeries = stackGenerator(dataByGroup)

  const serieLength = stackSeries[0]?.length ?? 0
  const maxValues = Array<number>(serieLength)
  for (let i = 0; i < serieLength; i++) {
    maxValues[i] = Math.max(...stackSeries.map(f => f[i][1]).filter(Number.isFinite))
  }

  return { stackSeries, maxValues }
}

function getDateFormatString(dateFormat?: DateFormat) {
  switch (dateFormat) {
    case 'week':
      return 'I/RRRR'
    case 'fy':
      return "'FY'yy"
    case 'month':
      return 'MMM'
    case 'monthWithYear':
    default:
      return 'MMM yy'
  }
}

export function max(series: SimpleBarSerie[]) {
  return Math.max(
    ...flatMap(series, s => flatMap(s.data, d => [d.value, d.overlay?.value ?? 0])).filter(Number.isFinite)
  )
}

function parseColor(color: string | Record<string, ColorDef>, key?: string) {
  if (typeof color === 'string') {
    return { color: color, opacity: 1 }
  }
  const c = color[key as keyof Record<string, ColorDef>] as ColorDef
  return { color: c.color, opacity: c.opacity ?? 1 }
}

function formatValue(v: DataPointKeyType, dateFormat?: DateFormat): string {
  if (v == null) {
    return ''
  }
  if (typeof v === 'string') {
    return v
  }

  return formatDate(addYears(v, dateFormat === 'fy' ? 1 : 0), getDateFormatString(dateFormat))
}

interface PercentageBarChartProps {
  color: string
  label: string
  percentage: number
  badge?: string
  fillRestWithGrey?: boolean
}

export const PercentageBarChart: React.FC<PercentageBarChartProps> = ({
  color,
  label,
  percentage,
  badge,
  fillRestWithGrey = true
}) => {
  const totalHeight = 240
  const barHeight = (totalHeight * percentage) / 100
  const labelWithLinebreak = label.replace(' ', '\n')
  return (
    <div className="PercentageBarChart">
      <svg width="40" viewBox="0 0 40 240">
        {fillRestWithGrey && <rect x={0} y={0} width={40} height={totalHeight} rx={8} fill={colours.offWhite1} />}
        <rect x={0} y={totalHeight - barHeight} width={40} height={barHeight} rx={8} fill={color} />
      </svg>
      <p className="ChartLabel">{labelWithLinebreak}</p>
      {badge && <p className="ChartBadge">{badge}</p>}
    </div>
  )
}

interface ValueWithColor {
  color: string
  value: number
  label?: string
}

interface ComparisonBarChartProps {
  className?: string
  current: ValueWithColor
  label?: string
  previous: ValueWithColor
  tooltipSettings?: TooltipSettings
}

export const VerticalComparisonBarChart: React.FC<ComparisonBarChartProps> = ({
  className,
  current,
  label,
  previous,
  tooltipSettings
}) => {
  const [tooltipPosition, setTooltipPosition] = React.useState<TooltipPosition>()

  const totalHeight = 240
  const barWidth = 40
  const barMargin = 8
  const previousBarHeight =
    current.value === 0
      ? 1
      : Math.max(
          1, // Always show minimal bar even if the value is zero
          previous.value > current.value ? totalHeight : (totalHeight * previous.value) / current.value
        )
  const currentBarHeight =
    previous.value === 0
      ? 1
      : Math.max(1, current.value > previous.value ? totalHeight : (totalHeight * current.value) / previous.value)

  const series = [
    {
      name: previous.label || 'Previous',
      color: previous.color,
      value: previous.value
    },
    {
      name: current.label || 'Current',
      color: current.color,
      value: current.value
    }
  ]

  const tooltipData = {
    tooltipContent: {
      heading: tooltipSettings?.title,
      items: flatten(series).map(({ name, color, value }) => ({
        name,
        color,
        value: tooltipSettings?.valueFn ? tooltipSettings?.valueFn(value) : value
      }))
    }
  }

  const mouseMoved = (event: React.MouseEvent) => {
    if (!tooltipSettings) return

    setTooltipPosition({
      x: event.clientX,
      y: event.clientY
    })
  }

  return (
    <div className={classNames('VerticalComparisonBarChart', className)}>
      <svg
        width="100%"
        viewBox={`0 0 ${barWidth * 2 + barMargin} ${totalHeight}`}
        onMouseMove={mouseMoved}
        onMouseOut={() => setTooltipPosition(undefined)}
      >
        <rect
          x={0}
          y={previous.value > current.value ? 0 : totalHeight - previousBarHeight}
          width={barWidth}
          height={previousBarHeight}
          rx={8}
          fill={previous.color}
        />
        <rect
          x={barWidth + barMargin}
          y={current.value > previous.value ? 0 : totalHeight - currentBarHeight}
          width={barWidth}
          height={currentBarHeight}
          rx={8}
          fill={current.color}
        />
      </svg>
      <p className="ChartLabel">{label}</p>
      {tooltipPosition && (
        <ChartTooltip
          subHeading={tooltipSettings?.subtitle}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          data={tooltipData}
        />
      )}
    </div>
  )
}

interface SideBySideComparisonBarChartProps {
  left: ValueWithColor
  right: ValueWithColor
  className?: string
  label?: string
  hideSideLabels?: boolean
}

export const SideBySideComparisonBarChart: React.FC<SideBySideComparisonBarChartProps> = ({
  className,
  left,
  right,
  label,
  hideSideLabels = false
}) => {
  const maxBarWidth = 160
  const totalWidth = maxBarWidth * 2
  const height = 40
  const cornerRadius = 8
  const leftWidth = maxBarWidth * (left.value / 100)
  const rightWidth = maxBarWidth * (right.value / 100)
  return (
    <div className={classNames('SideBySideComparisonBarChart', className)}>
      {label && <div className="SideBySideLabel">{label.replace('´', "'")}</div>}
      <div className="SideBySideGraph">
        {!hideSideLabels && <span className="LabelLeft">{`${left.value.toFixed(1)}%`}</span>}
        <svg width="100%" viewBox={`0 0 ${totalWidth} ${height}`}>
          <clipPath id="hide-left">
            <rect x={maxBarWidth} y={0} width={maxBarWidth} height={height} />
          </clipPath>
          <clipPath id="hide-right">
            <rect x={0} y={0} width={maxBarWidth} height={height} />
          </clipPath>
          <rect x="0" y="0" width={totalWidth} height={height} rx={cornerRadius} fill={colours.offWhite1} />
          <rect
            x={maxBarWidth - leftWidth}
            y={0}
            width={leftWidth + cornerRadius}
            height={height}
            fill={left.color}
            rx={cornerRadius}
            clipPath="url(#hide-right)"
          />
          <rect
            x={maxBarWidth - cornerRadius}
            y={0}
            width={rightWidth + cornerRadius}
            height={height}
            fill={right.color}
            rx={8}
            clipPath="url(#hide-left)"
          />
        </svg>
        {!hideSideLabels && <span className="LabelRight">{`${right.value.toFixed(1)}%`}</span>}
      </div>
    </div>
  )
}

interface SimpleVerticalBarChartProps {
  className?: string
  margin?: number
  series: {
    color: string
    label: string
    value: number
  }[]
  fillTo?: number
}

export const SimpleVerticalBarChart: React.FC<SimpleVerticalBarChartProps> = ({
  className,
  fillTo,
  margin = 32,
  series
}) => {
  const totalHeight = 240
  const barWidth = 40
  const max = fillTo || Math.max(...series.map(({ value }) => value).filter(Number.isFinite))

  return (
    <div className={classNames('SimpleVerticalBarChart', className)}>
      <svg width="100%" viewBox={`0 0 ${barWidth * series.length + margin * (series.length - 1)} ${totalHeight}`}>
        {series.map(({ value, color }, index) => (
          <React.Fragment key={`SimpleVerticalBar${index}`}>
            {fillTo && (
              <rect
                x={index * barWidth + index * margin}
                y={0}
                width={barWidth}
                height={totalHeight}
                rx={8}
                fill={colours.offWhite1}
              />
            )}
            <rect
              x={index * barWidth + index * margin}
              y={totalHeight - (totalHeight * value) / max}
              width={barWidth}
              height={Math.max(1, (totalHeight * value) / max)} // Always show minimal bar even if the value is zero
              rx={8}
              fill={color}
            />
          </React.Fragment>
        ))}
      </svg>
      <div className="ChartLabels">
        {series.map(({ label }) => (
          <div key={label} className="ChartLabel">
            <span>{label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
