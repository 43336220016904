import React from 'react'
import { GoalsResponse } from '../../../../../api/src/types/goals'
import { curry } from 'lodash'
import { emptyResult, formatToPercentagesInit, sortByLocationName } from './helpers'
import { BenchmarkingContext, CurrentFyTimeRange, PreviousFyTimeRange } from '../Provider'
import { getGoalsForLocation } from '../../../../components/Utils/utils'
import { LocationResults } from '../../../../components/Benchmark/SiteResultsTable'
import { BenchmarkTimeRanges, ProfitBenchmarkResult } from '../../../../../api/src/types/benchmark'

const getPppSales = curry(
  (
    prevTimeRange: PreviousFyTimeRange,
    currTimeRange: CurrentFyTimeRange,
    isChild: boolean,
    goals: GoalsResponse | undefined,
    goalsNextFy: GoalsResponse | undefined,
    goalsPrevFy: GoalsResponse | undefined,
    result: BenchmarkTimeRanges<ProfitBenchmarkResult>
  ): LocationResults => {
    return {
      selectable: true,
      locationName: result?.ytd?.name,
      ...((result.ytd || result.rolling12) && {
        comparisonValue:
          currTimeRange === 'YTD'
            ? result.ytd.ppp_asis_sales_csum / result.ytd.total_sales_csum
            : result.rolling12?.ppp_asis_sales_csum / result.rolling12?.total_sales_csum
      }),
      ...((result.previousFyYtd || result.previousFy) && {
        previousFy:
          prevTimeRange === 'YTD'
            ? result.previousFyYtd?.ppp_asis_sales_csum / result.previousFyYtd?.total_sales_csum
            : result.previousFy?.ppp_asis_sales_csum / result.previousFy?.total_sales_csum
      }),
      goal: getGoalsForLocation(result.ytd.id, goals)?.pppShareGoal ?? null,
      goalNextFy: getGoalsForLocation(result.ytd.id, goals)?.pppShareGoalNextFY ?? null,
      goalPrevFy: getGoalsForLocation(result.ytd.id, goals)?.pppShareGoalPrevFY ?? null
    }
  }
)

export const useProfitData = () => {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const { prevTimeRange, currTimeRange, goals, goalsNextFy, goalsPrevFy, profit } = context.data

  const profitData = !profit
    ? null
    : {
        locationResult:
          profit.status === 'ok'
            ? formatToPercentagesInit(false)(
                getPppSales(
                  prevTimeRange,
                  currTimeRange,
                  false,
                  goals,
                  goalsNextFy,
                  goalsPrevFy,
                  profit.selectedLocation
                )
              )
            : emptyResult,
        childLocationResults: (profit.status === 'ok' ? Object.values(profit.childLocations).filter(x => x.ytd) : [])
          .map(
            getPppSales(prevTimeRange, currTimeRange, true, goals, goalsNextFy, goalsPrevFy) as () => LocationResults
          )
          .map(formatToPercentagesInit(false))
          .sort(sortByLocationName)
      }

  return {
    profitData
  }
}
