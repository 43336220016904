import React, { useEffect } from 'react'
import {
  CountryWithType,
  FootprintType,
  getPlanetFootprints,
  isCountry,
  SelectorWithCountryCodes
} from '../lib/APIClient'

import colours from '../Colours.module.scss'
import { colorScheme } from './BaseGraphs/Universal'
import { GraphUnit, UnitSelector } from './UnitSelector'
import { ApiWasteFootprint } from '../../api/src/common-types'
import { DateFormat, lineChart } from './BaseGraphs/GraphUtil'
import { chain, isEqual, toPairs, uniq } from 'lodash'
import { useSharedSelections } from '../SharedSelections'
import { getFinancialYearTimeRange } from './Utils/dates'
import { useDataAvailabilityContext, useLocations, LanguageContext } from '../context'
import { InStoreI18n, Localisation } from '../Localisation'
import { NoDataView } from './BaseGraphs/NoDataView'
import { isCluster } from './Utils/utils'
import { ChartContainer, Serie } from './BaseGraphs/ChartContainer'

export const wasteDisposalColorMap: Record<string, string> = {
  'Material recycling': colours.lightBlue3,
  Landfill: colours.lightPink2,
  'Incineration with energy recovery': colours.green1,
  'External sorting facility': colours.orange,
  Compost: colours.blue5,
  Biogas: colours.yellow3,
  'IKEA Circular flows': colours.lightBlue4,
  '(no value)': colours.lightGreen,
  'Incineration without energy recovery': colours.offWhite7
}
export const recycledDisposalTypes = [
  'aerobic-digestion',
  'anaerobic-digestion',
  'ikea-circular-flows',
  'material-recycling'
]

export async function fetchWasteData(selector: SelectorWithCountryCodes) {
  const response = await getPlanetFootprints(FootprintType.Waste, selector)
  return { ...response, selector }
}

type DataKeyType = 'wasteType' | 'disposalTypeDescription' | 'siteId' | 'countryCode'
interface PlanetWasteBaseGraphProps {
  data: ApiWasteFootprint[]
  dataKey: DataKeyType
  dateFormat: DateFormat
  colors: Record<string, string>
  siteOrCountry: string
  selactedUnit?: (unit: string) => void
  selactedUnitBylocations?: (unit: string) => void
}

const PlanetWasteBaseGraph: React.FC<PlanetWasteBaseGraphProps> = props => {
  const { data, dataKey, colors } = props
  const [{ func, wasteUnit, rangeFrom, rangeTo, wasteUnitBylocations }, updateSharedSelections] = useSharedSelections()
  const { locations } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const setWasteUnit = React.useCallback(
    updatedUnit => updateSharedSelections({ wasteUnit: updatedUnit }),
    [updateSharedSelections]
  )
  const setWasteUnitBylocations = React.useCallback(
    updatedUnit => updateSharedSelections({ wasteUnitBylocations: updatedUnit }),
    [updateSharedSelections]
  )
  const lang = React.useContext(LanguageContext)

  const series = React.useMemo(() => {
    const countries = locations.filter(isCountry)
    const dataByLocation = data.reduce((result, d) => {
      const key = d[dataKey]
      const dateKey = props.dateFormat === 'fy' ? `${Number(d.fiscalYear) + 1999}-09-30` : d.readableDate
      const dataForLocation = result[key] ?? {
        name: parseName(dataKey, d, countries, key),
        description: dataKey === 'siteId' ? d.siteName : parseName(dataKey, d, countries, key),
        data: [],
        color: colors[key]
      }
      const curr = dataForLocation.data[dateKey] ?? { total: 0, recycled: 0, landfill: 0 }
      const recycled = recycledDisposalTypes.includes(d.disposalType) ? d.raw : 0
      const landfill = d.disposalType === 'landfill' ? d.raw : 0
      return {
        ...result,
        [key]: {
          ...dataForLocation,
          data: {
            ...dataForLocation.data,
            [dateKey]: {
              total: curr.total + d.raw,
              recycled: curr.recycled + recycled,
              landfill: curr.landfill + landfill
            }
          }
        }
      }
    }, {} as Record<string, { name: string; description: string; data: Record<string, { total: number; recycled: number; landfill: number }>; color: string }>)

    const divideToResultOrZero = (numerator: number, denominator: number) =>
      denominator === 0 ? 0 : numerator / denominator
    return toPairs(dataByLocation)
      .map(([key, data], i) => {
        return {
          id: key,
          name: dataKey === 'disposalTypeDescription' ? disposalText(data.name, InStoreI18n[lang]) : data.name,
          description: dataKey !== 'disposalTypeDescription' ? data.description : undefined,
          data: toPairs(data.data).map(([date, d]) => ({
            x: new Date(date),
            y:
              dataKey === 'disposalTypeDescription' ||
              (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RawWasteKg
                ? d.total
                : (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RecycledWaste
                ? divideToResultOrZero(d.recycled, d.total) * 100
                : divideToResultOrZero(d.landfill, d.total) * 100
          })),
          color: data.color,
          zIndex: i,
          unit:
            dataKey === 'disposalTypeDescription' ||
            (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RawWasteKg
              ? ''
              : '%'
        }
      })
      .filter(s => s.data.reduce((sum, w) => sum + w.y, 0) > 0)
  }, [locations, data, wasteUnitBylocations, wasteUnit])

  const additionalComponent =
    dataKey === 'countryCode' || dataKey === 'siteId' ? (
      <UnitSelector
        enabled
        value={props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit}
        options={[GraphUnit.RawWasteKg, GraphUnit.RecycledWaste, GraphUnit.Landfill]}
        onChange={val => {
          props.selactedUnitBylocations ? setWasteUnitBylocations(val) : setWasteUnit(val)
        }}
        menuPlacement="top"
      />
    ) : null

  const unitText =
    dataKey === 'disposalTypeDescription' ||
    (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RawWasteKg
      ? 'kg'
      : (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RecycledWaste
      ? 'Recycling %'
      : 'Landfill %'

  const domain =
    rangeTo === rangeFrom && Number(rangeFrom) === dataAvailability?.planetCurrentFY
      ? getFinancialYearTimeRange(dataAvailability.planetCurrentFY).map(x => new Date(x))
      : domainFromSeries(series)

  useEffect(() => {
    if (props.selactedUnit || props.selactedUnitBylocations) {
      props.selactedUnitBylocations
        ? props.selactedUnitBylocations && props.selactedUnitBylocations(wasteUnitBylocations)
        : props.selactedUnit && props.selactedUnit(wasteUnit)
    }
  }, [wasteUnit, wasteUnitBylocations])

  return isEqual(func, ['Tenants']) ? (
    <NoDataView text="Waste data is attributed to Common Areas" />
  ) : (
    <ChartContainer
      generator={lineChart}
      series={sortSeriesByBiggestContributor(series)}
      domain={domain}
      dateFormat={props.dateFormat}
      yAxisTitle={unitText}
      additionalComponents={additionalComponent}
      lineChartConfiguration={{
        focusStyle: dataKey === 'disposalTypeDescription' ? 'none' : 'nearest',
        startFromZero: false
      }}
      showDeselectAll
      deselectAllUnit={dataKey === 'disposalTypeDescription' ? 'treatment types' : undefined}
    />
  )
}

function domainFromSeries(series: Serie[]): Date[] {
  return chain(series)
    .flatMap(serie => serie.data)
    .map(d => d.x)
    .sort((a, b) => a.getTime() - b.getTime())
    .uniqBy(d => d.getTime())
    .value()
}

const parseName = (dataKey: DataKeyType, data: ApiWasteFootprint, countries: CountryWithType[], key: string) => {
  return dataKey === 'siteId'
    ? data.siteName.split(' ')[0]
    : dataKey === 'countryCode'
    ? countries.find(c => c.countryCode === data.countryCode)?.countryName ?? data.countryCode
    : key
}

export const PlanetWasteByDisposalGraph: React.FC<Omit<PlanetWasteBaseGraphProps, 'dataKey' | 'colors'>> = props => {
  return PlanetWasteBaseGraph({ ...props, dataKey: 'disposalTypeDescription', colors: wasteDisposalColorMap })
}

const disposalText = (disposal: string, localisations: Localisation) => {
  switch (disposal) {
    case 'Material recycling':
      return localisations['Waste Types']['Material recycling']
    case 'External sorting facility':
      return localisations['Waste Types']['External sorting facility']
    case 'Incineration without energy recovery':
      return localisations['Waste Types']['Incineration without energy recovery']
    case 'Biogas':
      return localisations['Waste Types']['Biogas']
    case 'Incineration with energy recovery':
      return localisations['Waste Types']['Incineration with energy recovery']
    case 'IKEA Circular flows':
      return localisations['Waste Types']['IKEA Circular flows']
    case '(no value)':
      return localisations['Waste Types']['(no value)']
    case 'Compost':
      return localisations['Waste Types']['Compost']
    case 'Landfill':
    default:
      return localisations['Waste Types']['Landfill']
  }
}

export const PlanetWasteByLocationGraph: React.FC<Omit<PlanetWasteBaseGraphProps, 'dataKey' | 'colors'>> = props => {
  const { data, siteOrCountry } = props
  const dataKey = siteOrCountry === 'ALL' || isCluster(siteOrCountry) ? 'countryCode' : 'siteId'
  const colors = colorScheme(uniq(data.map(d => d[dataKey])))

  return PlanetWasteBaseGraph({ ...props, dataKey, colors })
}

function sortSeriesByBiggestContributor(series: Serie[]) {
  return series
    .sort((lhs, rhs) => {
      const lSum = lhs.data.reduce((sum, d) => sum + (d.y ?? 0), 0)
      const rSum = rhs.data.reduce((sum, d) => sum + (d.y ?? 0), 0)
      return rSum - lSum
    })
    .map((d, i) => ({ ...d, zIndex: i }))
}
