import React from 'react'

const ArrowRight = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0008 8.0001L8.00084 -0.000976562L6.58653 1.41314L12.1727 7.00003H0V9.00003H12.1723L6.58546 14.5866L7.99964 16.0008L16.0008 8.0001Z"
        fill="#111111"
      />
    </svg>
  )
}

export default ArrowRight
