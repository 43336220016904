import React, { Fragment, ReactElement } from 'react'
import classNames from 'classnames'
import { isSameDay, startOfMonth, addYears } from 'date-fns'

import { scaleBand, ScaleLinear, scaleLinear, ScalePoint, scalePoint } from 'd3-scale'
import { calculateHorizontalGuides, DateFormat, formatDate } from './GraphUtil'
import { ChartTooltip, ChartTooltipProps, SummaryItem, TooltipProps } from './Tooltip'
import { Tooltip } from '../Tooltip'
import { ActiveIndicator, InactiveIndicator } from './Indicators'
import { flatMap, uniqBy, upperFirst } from 'lodash'
import { LoadingSkeleton } from '../LoadingSkeleton'
import { NoDataView, NoDataViewSmall } from './NoDataView'
import { ChartDataContext } from '../../pages/ExplorePages/ExploreGraphCard'
import colours from '../../Colours.module.scss'
import './ChartContainer.scss'
import './Legend.scss'
import { Show } from '../Conditions/Show'
import { Slashes } from './Patterns'

type SeriesFocusStyle = 'none' | 'nearest' | 'top'

export interface DataPoint {
  unit?: string
  actionTypes?: {
    procurements: number
    expansions: number
    reltp: number
  }
  color?: string
  x: Date
  y: number
  startPoint?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDataPoint(obj: any): obj is DataPoint {
  return 'x' in obj && obj.x instanceof Date && 'y' in obj && typeof obj.y === 'number'
}

export interface Serie {
  unit?: string
  id?: string
  name: string
  color: string
  fill?: string
  data: DataPoint[]
  secondaryName?: string
  secondaryColor?: string
  secondaryData?: DataPoint[]
  secondaryUnit?: string
  startAtYAxis?: boolean
  hideOnTooltip?: boolean
  isBackgroundSerie?: boolean
}

function toSeparateSeries(serie: Serie) {
  return [
    ...(serie.secondaryName && serie.secondaryColor && serie.secondaryData
      ? [
          {
            name: serie.secondaryName,
            color: serie.secondaryColor,
            data: serie.secondaryData,
            fill: undefined,
            hideOnTooltip: false,
            unit: serie.secondaryUnit
          }
        ]
      : []),
    {
      name: serie.name,
      color: serie.color,
      fill: serie.fill,
      data: serie.data,
      hideOnTooltip: serie.hideOnTooltip ?? false,
      unit: serie.unit
    }
  ]
}

interface LineChartProps {
  startFromZero: boolean
  focusStyle: SeriesFocusStyle
}

export interface GeneratorProps {
  domain: Date[]
  series: Serie[]
  xScale: ScalePoint<Date>
  yScale: ScaleLinear<number, number, never>
  highlightedSerie: string | undefined
  maxValue?: number
  isBoldGraphLine?: boolean
}

export interface ChartContainerProps {
  domain: Date[] | undefined
  dateFormat: DateFormat
  series: Serie[] | undefined
  yAxisTitle?: string
  yAxisLabelTooltipOnly?: boolean
  lineChartConfiguration?: undefined | LineChartProps
  generator: (x: GeneratorProps) => JSX.Element[]
  tooltipSummary?: (date: Date) => SummaryItem[] | undefined
  tooltipItemsFn?: (date: Date, data: TooltipItem[]) => TooltipItem[]
  tooltipSvgTargetFn?: (name: string, type: string) => TooltipProps | undefined
  additionalComponents?: React.ReactNode
  hideLegend?: boolean
  disableLegendItems?: boolean
  customLegend?: JSX.Element
  calculateDifferenceInTooltip?: string
  legendOrder?: string[]
  showDeselectAll?: boolean
  deselectAllUnit?: string
  hideGuides?: boolean
  hideXAxis?: boolean
  hideYAxis?: boolean
  hideTooltip?: boolean
  highLightedSerieName?: string
  disableIndicator?: boolean
  isSmallGraph?: boolean
  maxValue?: number
  showDecimals?: boolean
  testId?: string
  forceNoData?: boolean
  graphName?: string
  modalHandler?: (year: number) => () => void
  actionsSumForEachYear?: Record<string, number>
  CustomTooltip?: React.FC<ChartTooltipProps>
  isSort?: boolean
  isWasteChart?: boolean
  withDynamicFormatting?: boolean
  isBoldGraphLine?: boolean
  notShowNA?: boolean
  yTicksModifier?: (value: number) => number | null
}

export interface TooltipItem {
  name: string
  value: number
  color: string
  icon?: JSX.Element
}

interface TooltipContent {
  date: Date
  items: TooltipItem[]
  summary: SummaryItem[] | undefined
}

interface DataForDate {
  startPoint: number
  name: string
  color: string
  value: number
  distance: number
  icon?: ReactElement
  hideOnTooltip: boolean
}

type TooltipData =
  | { tooltipContent: TooltipContent; tooltipProps?: never }
  | { tooltipContent?: never; tooltipProps: TooltipProps }

const tooltipItemsIdentityFn = (date: Date, x: TooltipItem[]) => x

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState([0, 0])
  React.useEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return windowSize
}

export const useResizableSVG = (svgRef: React.RefObject<SVGSVGElement>) => {
  const windowSize = useWindowSize()
  const [graphSize, setGraphSize] = React.useState([0, 0])
  const [yAxisLabelWidth, setYAxisLabelWidth] = React.useState(0)
  const [valueLabelWidth, setValueLabelWidth] = React.useState(0)

  const yAxisContent = JSON.stringify(Array.from(document.querySelectorAll('.YAxisValue')).map(el => el.innerHTML))
  React.useLayoutEffect(() => {
    if (svgRef.current == null) {
      return
    }

    const svg = svgRef.current
    const box = svg.getClientRects()[0]
    setGraphSize([box.width, box.height])

    const maxYAxisLabelWidth = Math.max(
      ...Array.from(svg.querySelectorAll('.YAxisValue')).map(el =>
        (el as SVGTextContentElement).getComputedTextLength()
      )
    )
    if (Number.isFinite(maxYAxisLabelWidth)) {
      setYAxisLabelWidth(maxYAxisLabelWidth)
    }

    const maxValueLabelWidth = Math.max(
      ...Array.from(svg.querySelectorAll('.HorizontalBarChartValue')).map(el =>
        (el as SVGTextContentElement).getComputedTextLength()
      )
    )

    setValueLabelWidth(maxValueLabelWidth)
  }, [JSON.stringify(windowSize), svgRef.current, yAxisContent])
  return { graphWidth: graphSize[0], graphHeight: graphSize[1], yAxisLabelWidth, valueLabelWidth }
}

export function ChartContainer({
  domain,
  dateFormat,
  series,
  yAxisTitle,
  yAxisLabelTooltipOnly = false,
  lineChartConfiguration,
  generator,
  tooltipSummary,
  tooltipItemsFn,
  tooltipSvgTargetFn,
  additionalComponents,
  hideLegend,
  disableLegendItems,
  legendOrder,
  showDeselectAll,
  deselectAllUnit,
  hideGuides = false,
  hideXAxis = false,
  hideYAxis = false,
  hideTooltip = false,
  highLightedSerieName,
  disableIndicator,
  isSmallGraph,
  maxValue,
  showDecimals = false,
  calculateDifferenceInTooltip,
  testId,
  forceNoData = false,
  customLegend,
  graphName,
  modalHandler,
  actionsSumForEachYear,
  CustomTooltip,
  isSort,
  isWasteChart = false,
  withDynamicFormatting = false,
  isBoldGraphLine = false,
  notShowNA,
  yTicksModifier
}: ChartContainerProps) {
  const svgRef = React.useRef<SVGSVGElement>(null)
  const { graphWidth, graphHeight, yAxisLabelWidth } = useResizableSVG(svgRef)
  const [indicatorXPosition, setIndicatorXPosition] = React.useState<number | undefined>(undefined)
  const [mouseXPosition, setMouseXPosition] = React.useState<number | undefined>(undefined)
  const [mouseYPosition, setMouseYPosition] = React.useState<number | undefined>(undefined)
  const [tooltipYPosition, setTooltipYPosition] = React.useState<number | undefined>(undefined)
  const [tooltipData, setTooltipData] = React.useState<TooltipData | undefined>(undefined)
  const [selectedSeries, setSelectedSeries] = React.useState<string[]>([])
  const [highlightedSerie, setHighlightedSerie] = React.useState<string | undefined>(highLightedSerieName)
  const [circleYPosition, setCircleYPosition] = React.useState<number | undefined>(undefined)
  const margin = 4
  const xAxisHeight = 14
  const yScale = scaleLinear().rangeRound([graphHeight - xAxisHeight, 0])
  const xScale = lineChartConfiguration
    ? scalePoint<Date>().range([yAxisLabelWidth + margin, graphWidth])
    : scaleBand<Date>()
        .range([yAxisLabelWidth + margin, graphWidth])
        .paddingInner(0.2)

  const additionalDate = domain && lineChartConfiguration?.startFromZero ? [startOfMonth(domain[0])] : []
  const dates = domain ? [...additionalDate, ...domain] : undefined
  if (dates) {
    xScale.domain(dates)
  }

  const chartDataContext = React.useContext(ChartDataContext)
  React.useEffect(() => {
    if (dates !== undefined && series !== undefined) {
      chartDataContext.setValues(dates, dateFormat, series, lineChartConfiguration != null, yAxisTitle ?? '')
    }
  }, [JSON.stringify(series), JSON.stringify(domain), dateFormat])

  React.useEffect(() => {
    if (highLightedSerieName !== undefined) {
      setHighlightedSerie(highLightedSerieName)
    }
  }, [highLightedSerieName])

  React.useEffect(() => {
    if (series) {
      setSelectedSeries(flatMap(series, s => [s.name, ...(s.secondaryName ? [s.secondaryName] : [])]))
    }
  }, [JSON.stringify(series)])

  const lines = React.useMemo(
    () =>
      series && dates
        ? generator({
            domain: dates,
            series: series
              .filter(s => selectedSeries.includes(s.name))
              .map(s => ({
                ...s,
                data: additionalDate.length > 0 ? [{ x: additionalDate[0], y: 0 }, ...s.data] : s.data
              })),
            xScale,
            yScale,
            highlightedSerie,
            maxValue,
            isBoldGraphLine
          })
        : undefined,
    [JSON.stringify(dates), JSON.stringify(series), xScale, yScale]
  )

  const yTicks = hideYAxis
    ? []
    : calculateHorizontalGuides(
        yScale,
        graphWidth,
        yAxisLabelWidth,
        margin,
        hideGuides,
        yAxisTitle,
        withDynamicFormatting,
        yTicksModifier
      )
  const xTicks = React.useMemo(() => {
    if (hideXAxis || dates === undefined) {
      return []
    }
    const xTickStep =
      dateFormat === 'week'
        ? 8
        : dateFormat === 'monthTwoFYs'
        ? 3
        : (dateFormat === 'month' || dateFormat === 'monthWithYear') && (graphWidth < 600 || dates.length > 24)
        ? 2
        : 1

    return dates.map((d, i) => {
      const x = xScale(d) ?? 0
      const lineX = x + xScale.bandwidth() / 2
      const y = yScale(yScale.domain()[0])
      return i === 0 && lineChartConfiguration?.startFromZero ? (
        <Fragment key={`XAxisTick${i}`} />
      ) : (
        <g key={`XAxisTick${i}`}>
          <line x1={lineX} y1={y} x2={lineX} y2={y + 4} stroke="black" strokeWidth={0.5} />
          {i % xTickStep === 0 && (
            <text x={lineX} y={y + 15} fontFamily='"Noto Sans", sans-serif' fontSize="8px" textAnchor="middle">
              {formatDate(d, dateFormat, 'en')}
            </text>
          )}
        </g>
      )
    })
  }, [yAxisLabelWidth, dateFormat, JSON.stringify(dates), graphWidth, graphHeight])

  const topSerie = series && series.length > 0 ? series[series?.length - 1] : undefined
  const firstBarStart = dates ? xScale(dates[0]) : undefined
  const mouseMoved = (event: React.MouseEvent) => {
    if (!svgRef.current || series === undefined || dates === undefined) {
      return
    }

    const pt = svgRef.current.createSVGPoint()
    pt.x = event.clientX
    pt.y = event.clientY
    const { x, y } = pt.matrixTransform(svgRef.current.getScreenCTM()?.inverse())

    setTooltipYPosition(event.clientY)
    if (tooltipSvgTargetFn && !hideTooltip) {
      setMouseXPosition(x - 20)
      const name = (event.target as SVGElement).dataset['name']
      const type = (event.target as SVGElement).dataset['type']
      if (name && type) {
        const tooltipProps = tooltipSvgTargetFn(name, type)
        setTooltipData(tooltipProps ? { tooltipProps } : undefined)
      }
    } else if (!hideTooltip) {
      const roundingFn = lineChartConfiguration ? Math.round : Math.floor
      const index = roundingFn((x - (firstBarStart ?? 0)) / xScale.step())
      const date = dates[index]

      if (date === undefined) {
        setTooltipData(undefined)
        return
      }
      const domY = yScale.invert(y)
      const dataForDateWithoutDifference: DataForDate[] = flatMap(series, toSeparateSeries)
        .filter(serie => selectedSeries.includes(serie.name))
        .map(serie => {
          const serieData = serie.data.filter(d => isSameDay(d.x, date))
          const value = serieData.length === 0 ? NaN : serieData.reduce((acc, d) => acc + d.y, 0)
          return {
            name: serieData[0]?.color === '#dfdfdf' ? 'Goal ambition' : serie.name,
            color: serieData[0]?.color ? serieData[0].color : serie.color,
            value,
            distance: Math.abs(value - domY),
            hideOnTooltip: serie.hideOnTooltip ?? false,
            actionTypes: serieData[0]?.actionTypes,
            startPoint: serieData[0]?.startPoint || 0,
            unit: serie.unit
          }
        })
      const dataForDate = calculateDifferenceInTooltip
        ? dataForDateWithoutDifference.reduce((acc: DataForDate[], rec) => {
            if (calculateDifferenceInTooltip === rec.name) {
              const [previousFY, currentFY] = [acc[0], rec]
              const difference: number =
                ((currentFY.value - previousFY.value) / ((currentFY.value + previousFY.value) / 2)) * 100
              return currentFY.value && previousFY.value && difference < 0
                ? ([
                    ...acc,
                    rec,
                    {
                      name: `Difference to ${previousFY.name}`,
                      value: difference,
                      icon: <span style={{ fontWeight: 700, paddingLeft: '3px' }}>%</span>,
                      distance: 0,
                      color: ''
                    }
                  ] as DataForDate[])
                : [...acc, currentFY]
            }
            return [...acc, rec]
          }, [])
        : dataForDateWithoutDifference

      if (dataForDate.length === 0) {
        setTooltipData(undefined)
        return
      }

      const indicatorX = firstBarStart ? firstBarStart + xScale.bandwidth() / 2 + index * xScale.step() : x
      setIndicatorXPosition(indicatorX)
      setMouseXPosition(event.clientX)
      const closest =
        lineChartConfiguration?.focusStyle === 'top'
          ? dataForDate.find(d => d.name === topSerie?.name)
          : dataForDate.slice().sort((lhs, rhs) => lhs.distance - rhs.distance)[0]
      if (closest === undefined) {
        setTooltipData(undefined)
        return
      }
      setMouseYPosition(yScale(closest.value))
      const itemsFn = tooltipItemsFn ? tooltipItemsFn : tooltipItemsIdentityFn

      let formattedDate: Date
      if (dateFormat === 'monthTwoFYs' && index % 3 === 1) {
        formattedDate = addYears(date, 1)
      } else {
        formattedDate = date
      }

      const filterData = (data: DataForDate[]) => {
        if (notShowNA) return data.filter(item => !item.hideOnTooltip && item.value)
        return data.filter(item => !item.hideOnTooltip)
      }

      const filteredItems = lineChartConfiguration
        ? lineChartConfiguration?.focusStyle !== 'none'
          ? filterData([closest])
          : filterData(dataForDate)
        : filterData(dataForDate)

      setTooltipData({
        tooltipContent: {
          date: formattedDate,
          items: itemsFn(date, filteredItems),
          summary: tooltipSummary ? tooltipSummary(date) : undefined
        }
      })
      const startPoint = Math.round(dataForDate.filter(i => i.name === 'Planned activities')[0]?.startPoint)
      setCircleYPosition(
        yScale(startPoint - Math.round(dataForDate.filter(i => i.name === 'Planned activities')[0]?.value) / 2)
      )
    }
  }

  const updateSelectedItems = (name: string) => {
    if (disableLegendItems) {
      return
    }
    if (selectedSeries.includes(name)) {
      setSelectedSeries(selectedSeries.filter(s => s !== name))
    } else {
      setSelectedSeries([...selectedSeries, name])
    }
  }
  const isData =
    (isWasteChart
      ? series && series.length > 0
      : series && series.length > 0 && series.some(item => item.data.length > 0)) ?? true
  const yScaleYear = (tooltipData?.tooltipContent?.date?.getFullYear() ?? 0) + 1 - 2000

  return (
    <div className="ChartContainer" data-testid={testId}>
      <div className="ChartWithYTitle">
        {series === undefined ? (
          <LoadingSkeleton />
        ) : forceNoData || series.length === 0 || !isData ? (
          isSmallGraph ? (
            <NoDataViewSmall />
          ) : (
            <NoDataView />
          )
        ) : (
          <>
            {!yAxisLabelTooltipOnly && yAxisTitle && !hideYAxis && <div className="YAxisTitle">{yAxisTitle}</div>}
            <div className="SafariWorkaroundContainer">
              <svg
                className={classNames('Chart')}
                ref={svgRef}
                onMouseMove={mouseMoved}
                onMouseLeave={_ => {
                  setMouseXPosition(undefined)
                  setMouseYPosition(undefined)
                  setTooltipYPosition(undefined)
                  setTooltipData(undefined)
                }}
              >
                <Slashes />
                {yTicks}
                {xTicks}
                {lines}
                {indicatorXPosition &&
                  tooltipData &&
                  !disableIndicator &&
                  createTooltipLine(indicatorXPosition, graphHeight)}
                {indicatorXPosition &&
                  tooltipData &&
                  circleYPosition &&
                  !disableIndicator &&
                  actionsSumForEachYear &&
                  yScaleYear &&
                  actionsSumForEachYear[yScaleYear] &&
                  createTooltipCircle(
                    indicatorXPosition,
                    circleYPosition,
                    modalHandler && modalHandler(yScaleYear),
                    actionsSumForEachYear[yScaleYear]
                  )}
                {lineChartConfiguration &&
                  indicatorXPosition &&
                  mouseYPosition &&
                  tooltipData &&
                  createTooltipIndicator(
                    indicatorXPosition,
                    lineChartConfiguration?.focusStyle,
                    mouseYPosition,
                    tooltipColor(tooltipData)
                  )}
              </svg>
            </div>
          </>
        )}
      </div>
      {tooltipData && mouseXPosition && tooltipYPosition && !CustomTooltip && (
        <ChartTooltip
          subHeading={yAxisTitle}
          x={mouseXPosition}
          y={tooltipYPosition}
          data={tooltipData}
          dateFormat={dateFormat}
          showDecimals={showDecimals}
          previousFY={series && series[0].name}
          graphName={graphName}
          withDynamicFormatting={withDynamicFormatting}
        />
      )}
      {tooltipData && mouseXPosition && tooltipYPosition && CustomTooltip && (
        <CustomTooltip
          subHeading={yAxisTitle}
          x={mouseXPosition}
          y={tooltipYPosition}
          data={tooltipData}
          dateFormat={dateFormat}
          showDecimals={showDecimals}
          previousFY={series && series[0].name}
          graphName={graphName}
        />
      )}
      {!hideLegend && (
        <Legend
          leftMargin={yAxisLabelWidth + margin}
          series={series}
          selectedSeries={selectedSeries}
          onItemClicked={name => updateSelectedItems(name)}
          additionalComponents={additionalComponents}
          showDeselectAll={showDeselectAll}
          deselectAllUnit={deselectAllUnit}
          legendOrder={legendOrder}
          customLegend={customLegend}
          changeSelectionForAllItems={selectAll =>
            setSelectedSeries(selectAll && series ? series.map(s => s.name) : [])
          }
          setHighlightedSerie={(serieName, highlighted) => {
            if (!highLightedSerieName) {
              setHighlightedSerie(highlighted ? serieName : undefined)
            }
          }}
          isSort={isSort}
        />
      )}
    </div>
  )
}

function createTooltipLine(x: number, graphHeight: number) {
  return <path d={`M ${x} 0 V ${graphHeight}`} stroke={colours.grey4} strokeWidth={1} strokeDasharray={3} />
}

function createTooltipCircle(
  x: number,
  y: number,
  modalHandler: React.MouseEventHandler<SVGSVGElement> | undefined,
  circleVar: number
) {
  const radius = circleVar.toString().length >= 3 ? '15' : '10'
  return (
    <svg onClick={modalHandler} style={{ cursor: 'pointer' }}>
      <circle cx={x} cy={y} r={radius} stroke="black" fill="white" strokeWidth="1" />
      <text x={x} y={y + 1.25} textAnchor="middle" alignmentBaseline="middle" fontSize="12" fill="black">
        {circleVar}
      </text>
    </svg>
  )
}

function createTooltipIndicator(x: number, seriesFocusStyle?: SeriesFocusStyle, yPosition?: number, color?: string) {
  return (
    seriesFocusStyle !== 'none' &&
    Number.isFinite(yPosition) &&
    color && (
      <circle
        cx={x}
        cy={yPosition}
        r={4}
        stroke={color}
        strokeWidth={2}
        fill="white"
        style={{ pointerEvents: 'none' }}
      />
    )
  )
}

const tooltipColor = (tooltipData: TooltipData) => {
  return tooltipData.tooltipContent && tooltipData.tooltipContent.items.length > 0
    ? tooltipData.tooltipContent.items[0].color
    : 'transparent'
}

const getLegendOrder = (order: string[]) => (a: Serie, b: Serie) => {
  return order.indexOf(a.id ?? '') < order.indexOf(b.id ?? '') ? -1 : 1
}

interface LegendContextType {
  setItemHighlight: (serieName: string, highlighted: boolean) => void
  onItemClick: (name: string) => void
  selectedSeries: string[]
  series?: Serie[]
}

export const LegendContext = React.createContext<LegendContextType | null>(null)

interface LegendProps {
  leftMargin: number
  series: Serie[] | undefined
  selectedSeries: string[]
  onItemClicked: (name: string) => void
  additionalComponents?: React.ReactNode
  legendOrder?: string[]
  showDeselectAll?: boolean
  deselectAllUnit?: string
  changeSelectionForAllItems: (selectAll: boolean) => void
  setHighlightedSerie: (serieName: string, highlighted: boolean) => void
  customLegend?: JSX.Element
  isSort?: boolean
}

export function Legend({
  leftMargin,
  series,
  selectedSeries,
  onItemClicked,
  additionalComponents,
  legendOrder,
  showDeselectAll,
  deselectAllUnit = 'locations',
  changeSelectionForAllItems,
  setHighlightedSerie,
  customLegend,
  isSort = true
}: LegendProps) {
  const uniqueSeries = series
    ? uniqBy(legendOrder ? [...series].sort(getLegendOrder(legendOrder)) : series, 'name')
    : undefined
  const contextValue = {
    setItemHighlight: setHighlightedSerie,
    onItemClick: onItemClicked,
    selectedSeries,
    series: uniqueSeries
  }
  const sortedUniqueSeries = isSort
    ? uniqueSeries &&
      uniqueSeries.sort((a, b) => {
        const nameA = a.name?.toUpperCase()
        const nameB = b.name?.toUpperCase()

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      })
    : uniqueSeries?.reverse()

  return (
    <div className="Legend" style={{ marginLeft: `${leftMargin}px` }}>
      <LegendContext.Provider value={contextValue}>
        {customLegend}
        <Show when={!customLegend}>
          <div className="LegendItems">
            {flatMap(sortedUniqueSeries, serie =>
              toSeparateSeries(serie).map(s => (
                <LegendItem
                  key={s.name}
                  text={s.name}
                  color={s.color}
                  selected={selectedSeries.includes(s.name)}
                  onItemClick={() => onItemClicked(s.name)}
                  setItemHighlight={setHighlightedSerie}
                />
              ))
            )}
          </div>
        </Show>
      </LegendContext.Provider>
      {series && series.length > 1 && showDeselectAll && (
        <Tooltip
          tooltipText={selectedSeries.length > 0 ? `Hide all ${deselectAllUnit}` : `Show all ${deselectAllUnit}`}
          onClick={() => changeSelectionForAllItems(selectedSeries.length === 0)}
        >
          <div className="DeselectAll">
            <div className="Content">&#8856;</div>
          </div>
        </Tooltip>
      )}
      {additionalComponents && series && series.length > 0 && (
        <div className="AdditionalComponent">{additionalComponents}</div>
      )}
    </div>
  )
}

interface LegendItemProps {
  text: string
  color: string
  selected: boolean
  onItemClick?: () => void
  setItemHighlight?: (text: string, highlight: boolean) => void
}

export function LegendItem({ text, color, selected, onItemClick, setItemHighlight }: LegendItemProps) {
  const textRef = React.useRef<HTMLDivElement>(null)
  const [hasOverflowed, setOverflowed] = React.useState(false)

  React.useLayoutEffect(() => {
    if (!textRef.current) {
      return
    }
    setOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth)
  }, [textRef.current])

  const handleOver = (value: boolean) => () => {
    setItemHighlight && setItemHighlight(text, value)
  }

  return (
    <Tooltip
      tooltipText={hasOverflowed ? text : undefined}
      onClick={onItemClick}
      onEnter={handleOver(true)}
      onLeave={handleOver(false)}
      className="tooltip-legendItem-wrapper"
    >
      <div className={classNames('Item', { Faded: false })}>
        {selected ? (
          <ActiveIndicator
            className={classNames('LegendIcon', { Faded: false })}
            fill={color}
            style={{
              backgroundColor: color
            }}
          />
        ) : (
          <InactiveIndicator className="LegendIcon" fill={color} />
        )}
        <div ref={textRef} className="ItemText">
          {upperFirst(text)}
        </div>
      </div>
    </Tooltip>
  )
}
