import Carousel from '@ingka/carousel'
import React from 'react'
import { Insight as InsightType } from '../../api/src/common-types'

import './InPageInsights.scss'
import { Insight } from './Insights/Insight'

interface InPageInsightsProps {
  insights: InsightType[]
}

export const InPageInsights = ({ insights }: InPageInsightsProps) => {
  const [index, setIndex] = React.useState(0)

  const next = () => setIndex((index + 1) % insights.length)
  const previous = () => setIndex((index - 1 + insights.length) % insights.length)

  return (
    <Carousel
      slideIndex={index}
      hasNoScrollbar
      onBtnLeftClick={previous}
      onBtnRightClick={next}
      className="InsightCarousel"
      itemClass="InsightSlide"
      contentClass="InsightCarouselContent"
    >
      {insights.map(insight => (
        <React.Fragment key={insight.name}>
          <Insight index={index} insight={insight} insights={insights} setIndex={setIndex} />
        </React.Fragment>
      ))}
    </Carousel>
  )
}
