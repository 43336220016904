import React from 'react'
import deserves from '../../images/HowIsThisCalculated/SocialImpact/SocialImpactDeserves.png'

export const KpiChangeMakersModalContent = () => (
  <>
    <h3>Ingka x Neighbourhoods Approach</h3>
    <p>
      The Ingka x Neighbourhoods Approach, is connected to the Ingka Sustainability Strategy and provides the overall
      strategic direction for how we create positive social impact in the local communities where we operate,
      contributing to co-workers feeling pride in the Ingka Group as an employer and helping position the Ingka Group as
      a responsible company. Ingka x Neighbourhoods (IxN) is about creating positive social impact for the many people,
      using our unique business capabilities while at the same time benefitting our business.{' '}
    </p>
    <img src={deserves} alt="Every one deserves a better home, better life" style={{ width: '100%' }} />
    <p>
      We focus on people experiencing (or at risk of experiencing) poverty, and/or social exclusion. IxN movements and
      initiatives will contribute to the positive social impact we can create together with others. The scope of social
      impact reporting should always connect to the initiatives under IxN approach.{' '}
    </p>
    <h3>What metrics are we following? </h3>
    <p>
      Change Maker: People engaged to participate in social initiatives – Number of coworkers, customers and citizens
      engaged in participating in social initiatives{' '}
    </p>
    <p>
      A change-maker can be engaged in many different ways - through assembling furniture or signing a petition. At this
      moment, there is no evaluation of the type of engagement.{' '}
    </p>
    <h3>Co-Workers engaged </h3>
    <p>
      A number of co-workers are actively involved in the IxN initiative. The co-worker is engaged when all 4 criteria
      are met:{' '}
    </p>
    <ol>
      <li>If working time is paid </li>
      <li>If the initiative is concerning social impact and is supported or coordinated by Ingka </li>
      <li>If the social initiative supports people locally </li>
      <li>
        If the co-worker is actively contributing to the initiative (helping or raising awareness), not only
        communicating about what we do.{' '}
      </li>
    </ol>
    <p>This number will not include co-workers reached by the communication plans or advocacy campaigns. </p>
    <h3>Customers and citizens engaged </h3>
    <p>The number of customers that are actively involved in the IxN initiative. </p>
    <p>The customer is considered a change-maker if these 4 criteria are met: </p>
    <ol>
      <li>When the customer donates towards or is actively involved in a social initiative </li>
      <li>When the engagement is conscious </li>
      <li>When the prime reason for engagement is supporting a social cause </li>
      <li>When the cause aims to support people locally </li>
    </ol>
    <p>
      Within the social impact measurements context, we are also measuring the following indicators in 4P Dashboard
      and/or Social Impact Reporting Tool:{' '}
    </p>
    <p>People Supported Indicators: </p>
    <ul>
      <li>
        <b>Social Impact - People Supported by Substantive and Enduring Social Initiatives:</b> Number of beneficiaries
        directly supported by substantive and enduring social initiatives{' '}
      </li>
      <li>
        <b>People Supported in Total:</b> Total number of beneficiaries directly supported by social initiatives{' '}
      </li>
    </ul>
    <p>Resource Deployment Indicators: </p>
    <ul>
      <li>
        <b>Hours Spent by Coworkers:</b> Total working hours co-workers have dedicated to social initiatives{' '}
      </li>
      <li>
        <b>Donation Amount:</b> Total donation amount (€) Ingka allocated to social initiatives, including financial /
        cash donations and value of in-kind donations (CALC price).{' '}
      </li>
    </ul>
    <h3>How do we report and following our performance?</h3>
    <p>Performance can be viewed on both the Social Impact Reporting Tool and the 4P Dashboard. </p>
    <p>
      We have developed (and will continue improving) a Social Impact Reporting Tool to facilitate data collection
      process and the reporting tool should be used on a regular basis after completing each initiative.{' '}
    </p>
    <p>
      The 4P Dashboard is a tool that helps us measure the impact of Ingka operations, understand what actions we can
      take, and track our progress as we work together to become People and Planet Positive.{' '}
    </p>
    <h3>Additional information and documents </h3>
    <br />
    <a
      className="Underlined"
      href="https://iweof.sharepoint.com/:b:/r/sites/ingkagroup-psr/Shared%20Documents/Ingka%20in%20the%20local%20neighbourhoods.pdf?csf=1&web=1&e=hCK5ZM"
    >
      Group Rule on Ingka in the Local Neighbourhoods
    </a>
    <br />
    <a className="Underlined" href="#">
      Guideline for Reporting Social Impact Initiatives in the Local Neighbourhoods (The link will be provided when
      finalized)
    </a>
    <br />
    <a className="Underlined" href="https://sirt.ingka.com/">
      Social Impact Reporting Tool (SIRT)
    </a>
  </>
)
