import React from 'react'
import deserves from '../../images/HowIsThisCalculated/SocialImpact/SocialImpactDeserves.png'
import SocialImpactTable from '../../images/HowIsThisCalculated/SocialImpact/SocialImpactTable.png'
import SocialImpactSchema from '../../images/HowIsThisCalculated/SocialImpact/SocialImpactSchema.png'

export const KpiSocialImpactModalContent = () => (
  <>
    <h3>Ingka x Neighbourhoods Approach</h3>
    <p>
      The Ingka x Neighbourhoods Approach, is connected to the Ingka Sustainability Strategy and provides the overall
      strategic direction for how we create positive social impact in the local communities where we operate,
      contributing to co-workers feeling pride in the Ingka Group as an employer and helping position the Ingka Group as
      a responsible company. Ingka x Neighbourhoods (IxN) is about creating positive social impact for the many people,
      using our unique business capabilities while at the same time benefitting our business.
    </p>
    <img src={deserves} alt="Every one deserves a better home, better life" style={{ width: '100%' }} />
    <p>
      We focus on people experiencing (or at risk of experiencing) poverty, and/or social exclusion. IxN movements and
      initiatives will contribute to the positive social impact we can create together with others. The scope of social
      impact reporting should always connect to the initiatives under IxN approach.
    </p>
    <h3>What metrics are we following? </h3>
    <ul>
      <li>
        <b>Social Impact - People Supported by Substantive and Enduring Social Initiatives:</b> Number of beneficiaries
        directly supported by substantive and enduring social initiatives{' '}
      </li>
      <li>
        <b>People Supported in Total:</b> Total number of beneficiaries directly supported by social initiatives{' '}
      </li>
    </ul>
    <h3>People Supported / Beneficiaries Supported </h3>
    <p>
      We measure the number of beneficiaries - people experiencing (at risk of experiencing) poverty and social
      exclusion directly reached and benefited by social initiatives. We include only those beneficiaries who are part
      of the vulnerable group. We do not include people supported indirectly by our initiatives. Focusing on the direct
      beneficiaries adds credibility to the numbers reported.{' '}
    </p>
    <h3>Evaluation of Contribution Levels </h3>
    <p>
      We measure the beneficiaries directly affected/supported by social initiatives by evaluating our contribution
      through social initiatives. At this stage, we are evaluating the significance of contribution from our perspective
      - not assessing impact (the positive change that beneficiaries can report). We evaluate two variables:{' '}
    </p>
    <ul>
      <li>What is the type of our contribution / our input? - Limited or substantive; </li>
      <li>
        What is the duration of the partnership or collaboration in program? - Short-term or medium and long-term{' '}
      </li>
    </ul>
    <p>
      Based on this evaluation, the contribution level will be evaluated according to the table and the number of people
      supported by social initiative will be qualified with the level accordingly.{' '}
    </p>
    <img src={SocialImpactTable} alt="Evaluation of Contribution Levels " />
    <img src={SocialImpactSchema} alt="Social Impact Schema" />
    <p>
      Within the social impact measurements context, we are also measuring the following indicators in 4P Dashboard
      and/or Social Impact Reporting Tool:{' '}
    </p>
    <p>Change Maker Indicators: </p>
    <ul>
      <li>
        People engaged in participating in social initiatives: Number of coworkers, customers and citizens engaged in
        participating in social initiatives{' '}
      </li>
    </ul>
    <p>Resource Deployment Indicators: </p>
    <ul>
      <li>Hours Spent by Coworkers: Total working hours co-workers have dedicated to social initiatives </li>
      <li>
        Donation Amount: Total donation amount (€) Ingka allocated to social initiatives, including financial / cash
        donations and value of in-kind donations (CALC price).{' '}
      </li>
    </ul>
    <h3>How do we report and following our performance?</h3>
    <p>Performance can be viewed on both the Social Impact Reporting Tool and the 4P Dashboard. </p>
    <p>
      We have developed (and will continue improving) a Social Impact Reporting Tool to facilitate data collection
      process and the reporting tool should be used on a regular basis after completing each initiative.{' '}
    </p>
    <p>
      The 4P Dashboard is a tool that helps us measure the impact of Ingka operations, understand what actions we can
      take, and track our progress as we work together to become People and Planet Positive.{' '}
    </p>
    <h3>Additional information and documents </h3>
    <br />
    <a
      className="Underlined"
      href="https://iweof.sharepoint.com/:b:/r/sites/ingkagroup-psr/Shared%20Documents/Ingka%20in%20the%20local%20neighbourhoods.pdf?csf=1&web=1&e=hCK5ZM"
    >
      Group Rule on Ingka in the Local Neighbourhoods
    </a>
    <br />
    <a className="Underlined" href="#">
      Guideline for Reporting Social Impact Initiatives in the Local Neighbourhoods (The link will be provided when
      finalized)
    </a>
    <br />
    <a className="Underlined" href="https://sirt.ingka.com/">
      Social Impact Reporting Tool (SIRT)
    </a>
  </>
)
